import React ,{useEffect,Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import ReactLoading from 'react-loading';



import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import TextField from '@material-ui/core/TextField';


import Report from './Radiology/Report'

import ReportWithHeader from './RadiologyWithHeader/Report'
import { PDFViewer } from '@react-pdf/renderer';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const columns = 
  [
    { id: 'id', label: 'S/N' },
    { id: 'invoice', label: 'Invoice ID' },
    { id: 'select', label: 'Select' },
    { id: 'selectWithLogo', label: 'Select With Logo' },
    

]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
 return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '60%',
    alignContent:"center"
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime (ms) {
  var seconds = (ms);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return addZero(hours) + ':' + addZero(minutes) ;
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date=new Date()
  var temp_role=localStorage.getItem('role')
  

  const [selected,setSelected]=React.useState({
    reports:[]
  })
  
  const [OpenCovidTemplate,setCovidTemplate]=React.useState(false)

  const [OpenOfficialCovidTemplate,setOfficialCovidTemplate]=React.useState(false)

  const [patient_invoice,setPatientInvoice]=React.useState(false)
  
  const [from,setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel,setPanel]=React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows]= React.useState([]);
  const [show,setShow]=React.useState(false)
  const [Invoice_Aux_ID,setInvoice_Aux_ID]=React.useState("")
  const [User_ID,setUser_ID]=React.useState("")
  const [name,setName]=React.useState('')
  const [RefBy,setRefBy]=React.useState('')
  const [Ref_no,setRef_no]=React.useState("")
  const [invoice_id,setInvoiceID]=React.useState("")
  const [age_gender,setAgeGender]=React.useState("")
  const [Salutation,setSalutation]=React.useState('')
  const [TestTitle,setTestTitle]=React.useState('')
  const [InvoiceDate,setInoviceDate]=React.useState('')
  const [InvoiceTime,setInvoiceTime]=React.useState('')
  const [UserName,setUserName]=React.useState('')
  const [DoctorName,setDoctorName]=React.useState('')
  const [DoctorInfo,setDoctorInfo]=React.useState('')
  //////////////
  const [ReportDate,setReportDate]=React.useState('')
  const [ReportTime,setReportTime]=React.useState('')

  const [selectedFibro,setSelectedFibro]=React.useState({})
  const [selectedOfficialCovidReport,setSelectedOfficialCovidReport]=React.useState({
    reports:[]
  })

  const [org_rows,setOrg_Rows]= React.useState([]);
  const [loading,setLoading]=React.useState(true)

  const [covidButton,setCovidButton]=React.useState(false)

  const [panelList,setpanelList]=React.useState([])
  const [laborder,setLabOrder]=React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches,setBranches]=React.useState([])
  const [branch,setBranch]=React.useState("")
  const [selectBranch,setSelectBranch]=React.useState([])
  const [state, setState] = React.useState({
    status:'All',
  });
  const [showHeader,setShowHeader]=React.useState(false)
  var svgString2Image=(svgString, width, height, format, callback)=>{
    
    var svgStringFormat=window.atob(svgString)
    var temp=svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
        // clear canvas
        context.clearRect(0, 0, width, height);
        // draw image with SVG data to canvas
        context.drawImage(image, 0, 0, width, height);
        // snapshot canvas as png
        var pngData = canvas.toDataURL('image/' + format);
        // pass png data URL to callbac
        callback(pngData)
    }; // end async
    image.src=svgData
  
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
      localStorage.clear()
      window.location.href="https://radiology.mpl-labs.pk/login"
    }
    if(localStorage.getItem('radiology')==null || localStorage.getItem('radiology')==undefined){
      localStorage.clear()
      window.location.href="https://radiology.mpl-labs.pk/login"
    }
  
    var url ="https://reports.mpl-labs.pk:8443/invoices"
  
    fetch(url).then((res)=>res.json())
     .then((result)=>{
      var the_rows=[]
      result.map((data,index)=>{

        var obj={
          invoice:((data.split("/")[2]).split('.')[0]),
          id:index,
          select:<Button variant="contained" style={{backgroundColor:'white', color:'black'}} 
          onClick={()=>{
            var url ="https://reports.mpl-labs.pk:8443//data?invoice="+obj.invoice
  
            fetch(url).then((res)=>res.json())
             .then((result)=>{
               var obj1={}
              Object.keys(result).map((i)=>{
                obj1={
                  invoice_id:obj.invoice,
                  ...result[i]
                }
               })
               setSelectedFibro(obj1)
            
              setTimeout(() => {
                setShow(true)
              
              }, 2000);
            })

          }}
          >Simple Report</Button>,
          selectWithLogo:<Button variant="contained" style={{backgroundColor:'white', color:'black'}} 
          onClick={()=>{
            var url ="https://reports.mpl-labs.pk:8443//data?invoice="+obj.invoice
  
            fetch(url).then((res)=>res.json())
             .then((result)=>{
               var obj1={}
              Object.keys(result).map((i)=>{
                obj1={
                  invoice_id:obj.invoice,
                  ...result[i]
                }
               })
               setSelectedFibro(obj1)
            
              setTimeout(() => {
                setShowHeader(true)
              
              }, 2000);
            })

          }}
          >Report With Logo</Button>,

        }
     
        the_rows.push(obj)

      })
      
      setRows(the_rows)
      setOrg_Rows(the_rows)
      setLoading(false)
     })




  },[])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes(); 
    var hh = this.getHours();

    return [
        (hh > 9 ? '' : '0') + hh,
        (mm > 9 ? '' : '0') + mm
    ].join(':');
};

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
  return (
    <div style={{
      display:'flex',
      justifyContent:"center"
    }}>
    <Paper className={classes.root}>
      
        <div align="right">
               
               <TextField id="standard-basic" id="search_data" label="Search by Invoice ID"
             style={{maxWidth:'20em'}}
              fullWidth={true}
              
               onChange={async (e)=>{
                   var lab_tests=[]
                   var text=e.target.value
                  var data=await org_rows.map((item)=>{
                    if(item.id.toLowerCase().includes(text.toLowerCase())){
                      lab_tests.push(item)
                      return
                   
                  }
                 
               
                      
                      return item
              
                  })
                  Promise.all(data).then((item)=>{
                   
                     setRows(lab_tests)
                     
                  })
                  
            }}              
               />
            
               </div>
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container} >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code+Math.random()} >
                {columns.map((column) => {
                  const value = row[column.id];
                  var status_color= value=='Just Booked' ? 'brown' : value=='Only Phlebotomy Done' ? 'red' :
value=='Lying Pending Results' ? 'purple' : value=='Results Done But not Signed' ? 'blue' : value=='Results Ready' ? 'green' :'' 

                  return (
                  <>
                      {status_color.trim()!='' ?  
                      <TableCell key={column.id} align={column.align} style={{backgroundColor:status_color, color:'white'}} >
                     <i><b>{value}</b></i>
                                      </TableCell>
                      :   <TableCell key={column.id} align={column.align} >
                      {value}
                                       </TableCell>}
                   </>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
  
      
    </Paper>
    <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}

                  maxWidth={'xl'}
                  fullWidth={true}
                  open={show}
                  TransitionComponent={Transition}
                  keepMounted
                  
                  onClose={() => {setPatientInvoice(false)
                  setSelected({
                    reports:[]
                  })
                  }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>PDF Generation</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                 {
                  show && selectedFibro.invoice_id!=undefined ?
                   <center> <Fragment key={"sqq"}>
                   <PDFViewer width="1000" height="1000" >
                   <Report invoice={selectedFibro}/>
                   </PDFViewer>
                   </Fragment>
                </center>
       :null
                 }
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => {
                        setShow(false)
                     
                      }}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}

                  maxWidth={'xl'}
                  fullWidth={true}
                  open={showHeader}
                  TransitionComponent={Transition}
                  keepMounted
                  
                  onClose={() => {setPatientInvoice(false)
                  setSelected({
                    reports:[]
                  })
                  }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>PDF Generation</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                 {
                 showHeader &&  selectedFibro.invoice_id!=undefined ?
                   <center> <Fragment key={"sqq"}>
                   <PDFViewer width="1000" height="1000" >
                   <ReportWithHeader invoice={selectedFibro}/>
                   </PDFViewer>
                   </Fragment>
                </center>
       :null
                 }
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => {
                        setShowHeader(false)
                     
                      }}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
               
               
               
   
  </div>
  );
}
