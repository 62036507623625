import React, { useEffect } from 'react';
import { View, StyleSheet, Text,Image } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'

import liver from '../Liver.jpg'
import ultrasound from '../Ultrasound.jpg'
const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontFamily: "Helvetica",
    borderColor: 'black',
  },
  subReportTitle: {
    color: 'black',
        fontSize: 9,
        fontFamily:'Helvetica-Bold',
        paddingRight:5,
        paddingTop:5,
        textAlign: 'left'
  },
  GermTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  SusProfileTitle:{
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  MedicineGroupName:{
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  }
});


class InvoiceItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoice,
      line: 2,
      page: 1,
      CrossCheck: {},
      NextPage: 0,
      Garbage: [],
      counter: 0,
      page:0,
      Machine:"",
      singleTestCounter:0,
      MachineCommentsToShow:[],
      ShowMachineComments:true
    }
  }
  componentDidMount(){
    console.log(this.props.invoice)
  }

  render() {

    return <View style={{
      marginTop:-50
    }}>
              
{
  this.props.invoice.invoice_id!=undefined
  ?
          
  <View >
               
  <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        width: "100%",
        fontSize: 7,
        paddingLeft: 10,
        paddingRight: 10
      }}
      >
    
    
   </View>
<View style={styles.tableContainer} >

  <View style={{
    padding: 10,

  }}>            
  <InvoiceTitle title={"Fibroscan Report"} />
 
  <View >
  <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: "100%",
            fontSize: 7,
            marginTop: 0.5,
            borderWidth: 0.5
          }} >
             <View style={{
               width:"20%"
             }}></View>
            <View style={{
               width:"60%"
             }}>
            <Image style={{
             width: "100%",
             height: 160
          }} src={"data:image/jpg;base64,"+this.props.invoice.image} />

          </View>
          <View style={{
               width:"20%"
             }}></View>
            </View>

          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: "100%",
            fontSize: 7,
            marginTop: 5,
            borderWidth: 0.5
          }} >


            <View style={{

              width: '33.35%',
              textAlign: 'left',
              paddingTop: 2,
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
              textAlign: 'left',
           
            }}>
            
            <Text style={{
                fontFamily: "Helvetica-Bold",
                fontSize: 14
            }}>{"Stiffness (kPa)"}</Text>
           

          <View style={{
            flexDirection:'row',
            justifyContent:"space-between"
          
          }}>
             <Text style={{
                fontFamily: "Helvetica",
                paddingTop:4,
                fontSize: 10
            }}>{"Median"}</Text>
            <Text style={{
                fontFamily: "Helvetica-Bold",
                fontSize: 14
            }}>{(parseInt(this.props.invoice["Stiffness(kPa)"])).toFixed(2)}</Text>
          </View>
          <View style={{
            flexDirection:'row',
            justifyContent:"space-between"
          
          }}>
             <Text style={{
                fontFamily: "Helvetica",
              
                fontSize: 10
            }}>{"IQR/Median"}</Text>
            <Text style={{
                fontFamily: "Helvetica",
                fontSize: 10
            }}>{(parseInt(this.props.invoice["Stiffness IQR(kPa)"]))+"%"}</Text>
          </View>
            
            
            </View>
            <View style={{

              width: '33.35%',
              textAlign: 'left',
              paddingTop: 2,
              borderLeft:0.5,
              paddingLeft: 8,
              paddingRight: 8,
              flexDirection:"column",
              height: '100%',
              textAlign: 'left',
     
             

            }}>
                <Text style={{
                fontFamily: "Helvetica-Bold",
                fontSize: 14
            }}>{"Measurements"}</Text>

          <View style={{
            flexDirection:'row',
            justifyContent:"space-between"
          
          }}>
             <Text style={{
                fontFamily: "Helvetica",
                fontSize: 10,
                paddingTop:4,
            }}>{"Success Rate"}</Text>
            <Text style={{
              
                fontSize: 10,
                 paddingTop:4,
            }}>{((parseInt(this.props.invoice["Valid Measurements"])/parseInt(this.props.invoice["Total Measurements"]))*100).toFixed(2)+"%"}</Text>
          </View>
          <View style={{
            flexDirection:'row',
            justifyContent:"space-between"
          
          }}>
             <Text style={{
                fontFamily: "Helvetica",
              
                fontSize: 10
            }}>{"Valid/Total"}</Text>
            <Text style={{
                fontFamily: "Helvetica",
                fontSize: 10
            }}>{this.props.invoice["Valid Measurements"]+"/"+this.props.invoice["Total Measurements"]}</Text>
          </View>
            
            
              </View>
            <View style={{

              width: '33.35%',
              textAlign: 'left',
              paddingTop: 2,
              borderLeft:0.5,
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
              textAlign: 'left'
            }}>
               <Text style={{
                fontFamily: "Helvetica-Bold",
                fontSize: 14
            }}>{"UAP (dB/m)"}</Text>
               
          <View style={{
            flexDirection:'row',
            justifyContent:"space-between"
          
          }}>
             <Text style={{
                fontFamily: "Helvetica",
                paddingTop:4,
                fontSize: 10
            }}>{"Median"}</Text>
            <Text style={{
                fontFamily: "Helvetica-Bold",
                fontSize: 14
            }}>{parseInt(this.props.invoice["UAP(dB/m)"])}</Text>
          </View>
          <View style={{
            flexDirection:'row',
            justifyContent:"space-between"
          
          }}>
             <Text style={{
                fontFamily: "Helvetica",
              
                fontSize: 10
            }}>{"IQR/Median"}</Text>
            <Text style={{
                fontFamily: "Helvetica",
                fontSize: 10
            }}>{parseInt(this.props.invoice["UAP IQR(dB/m)"])+"%"}</Text>
          </View>
            
              
              </View>
          

          </View>
          <View
          style={
            {
              display:"flex",
              flexDirection:"column",
              justifyContent:"center",
              border:0.5,
              paddingTop:10,
              marginTop:5
            }
          }
          >
          <View
          style={
            {
              display:"flex",
              flexDirection:"row",
              justifyContent:"center"
             
            }
          }
          >
            <View style={{
              borderRight:3,
              borderStyle:"dashed",
              paddingRight:10,
              marginTop:-1
            }}>
          <Image style={{
             width: 240,
             height: 180
          }} src={liver} />
          </View>
          <Image style={{
             width: 250,
             height: 180
          }} src={ultrasound} />
          </View>
          <Text style={{
            fontSize:10,
            textAlign:"center",
            alignSelf:"center",
            marginTop:10
          }}>{"For Reference Only, Please consult your physician for further diagnosis"}</Text>
          </View>
         
          </View>
      
          </View>

          <View style={{
            paddingLeft:10,
            paddingRight:10
          }}>
          <Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>Kindly Remind:</Text>
           <Text style={{ 
            fontFamily: "Helvetica",
            fontSize: 7
           }}>LSM: <Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>1.0kPa</Text> ~ <Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>7.3kPa</Text> is normal</Text>
            <Text style={{ 
            fontFamily: "Helvetica",
            fontSize: 7
           }}>LSM: <Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>7.3kPa</Text> ~ <Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>12.4kPa</Text>, suggest for follow up test within 3-6 month and suggest to change the unhealthy living habbit e.g smoking, alcohal drinking, stay up late, eating irregularly or unhealthy, feel depression or irritable, less exercises etc.</Text>
          <Text style={{ 
            fontFamily: "Helvetica",
            fontSize: 7
           }}>LSM: more than <Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>12.4kPa</Text>, suggest for more diagnosis, in clinical, these reasons may cause the LSM high, such as: Hepatitis, Fatty liver, ALD, DILI, Autoimmune liver disease, or Biliary tract disease etc.</Text>
         <Text style={{ 
            fontFamily: "Helvetica",
            fontSize: 7
           }}>{"UAP : "}<Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>{"<244dB/m"}</Text>{" is normal"}</Text>
         
         <Text style={{ 
            fontFamily: "Helvetica",
            fontSize: 7
           }}>{"UAP : "}<Text style={{ 
            fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>{">244dB/m"}</Text>{" the more the UAP value, the higher Fat content in liver, suggest to control body weight, and adjust the diet, and do some exercies"}</Text>
         <Text style={{ 
           fontFamily: "Helvetica-Bold",
            fontSize: 7
           }}>Note</Text>
          <Text style={{ 
            fontFamily: "Helvetica",
            fontSize: 7
           }}>The following reasons will affect the LSM: Hepatic ascites, narrow intercostal space, hepatic inflammation ( abnormal bilirubin, abnormal transaminasem etc.), cholestasis, hepatic congestion, alcohol consumption etc.</Text>
         
          </View>
  </View>
</View>

:
null
}
      </View>

      
    
  }
}



export default InvoiceItemsTable