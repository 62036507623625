import 'react-quill/dist/quill.snow.css';


import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import ReactQuill, { Quill } from 'react-quill';
import { Wrapper } from '../components';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import jsPDF from 'jspdf'
import PDFViewer from 'pdf-viewer-reactjs'
import { RenderPageProps, Viewer } from '@react-pdf-viewer/core';
import TextField from "@material-ui/core/TextField";

import ReactLoading from 'react-loading';

import Barcode from 'react-barcode'

import bwipjs from 'bwip-js';
import autoTable from 'jspdf-autotable';

(function(API){
  API.myText = function(txt, options, x, y,z) {
      options = options ||{};
      /* Use the options align property to specify desired text alignment
       * Param x will be ignored if desired text alignment is 'center'.
       * Usage of options can easily extend the function to apply different text 
       * styles and sizes 
      */
     var x=0
      if( options.align == "center" ){
          // Get current font size
          var fontSize = this.internal.getFontSize();

          // Get page width
          var pageWidth = this.internal.pageSize.getWidth();
          console.log(pageWidth)

          // Get the actual text's width
          /* You multiply the unit width of your string by your font size and divide
           * by the internal scale factor. The division is necessary
           * for the case where you use units other than 'pt' in the constructor
           * of jsPDF.
          */
          var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;
          console.log(txtWidth)
          // Calculate text's x coordinate
          x = ( pageWidth - txtWidth ) / 2;
      }
         // for GroupTitleNew 13-dec-2022
         if( options.align == "start" ){
          // Get current font size
          var fontSize = this.internal.getFontSize();
  
          // Get page width
          var pageWidth = this.internal.pageSize.getWidth();
          console.log("pagewidth "+pageWidth)
  
          // Get the actual text's width
          /* You multiply the unit width of your string by your font size and divide
           * by the internal scale factor. The division is necessary
           * for the case where you use units other than 'pt' in the constructor
           * of jsPDF.
          */
          var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;
          console.log("txtwidth"+txtWidth)
          // Calculate text's x coordinate
          x = ( pageWidth - txtWidth ) / 12;
          console.log("value of x"+x)
      }
      this.line(x,152,x+txtWidth,152)
      // // Draw text at x,y
      // console.log(x)
      // console.log(y)
      // console.log(txt)
      // this.text(txt,x,y);
      // this.line(x,z,x+txtWidth,z)
      // Draw text at x,y
      console.log(x)
      console.log(y)
      console.log(txt)
      this.text(txt,x,y);
  }
})(jsPDF.API);

export default class RadiologyPDF extends React.Component {
  constructor (props) {
    super(props)
    this.state = { 
      editorHtml: '', theme: 'snow', pdf:"",   
      pdfHtml:"",
      PurePDF:"",
      DoctorName:"",
      DoctorInfo:"",
      loading:true,
      // 22-dec-2022 for title and image
      groupTitle:"",
      groupImage:"",
      // this margins value display result that are show in the pdf and coming from backend top bottom left right 
  margins :{
    top: 160,
      bottom: 120,
      left: 70,
      width: 450
     } }
     
    this.handleChange = this.handleChange.bind(this)
    this.generate = this.generate.bind(this)
    this.svgString2Image=this.svgString2Image.bind(this)
    this.base64toBlob=this.base64toBlob.bind(this)

  }
  componentDidMount(){
    this.setState({ testCode: this.props.testCode })
    var obj = {
      Invoice_Aux_ID: this.props.Invoice_Aux_ID + "",
      User_ID: localStorage.getItem("user_id"),
    }
    var url = "https://reports.mpl-labs.pk:8443/4DACTION/RadiologyTestsResultsViewModify"

    fetch(url, {
      method: 'POST',
      'Content-Type': "application/json",
      body: JSON.stringify(obj)
    }).then((res) => res.json()).then((res) => {
console.log("Radiology portal res",res)

      if (this.props.testCode == "ECHO") {
        this.setState({ ...JSON.parse(res[0].HTML_Report) })
      } else {
        document.getElementById('preview').innerHTML = res[0].HTML_Report
        this.setState({ editorHtml: res[0].HTML_Report })
      }
      this.setState({ loading: false })
      this.setState({ DoctorName: res[0].User_DoctorName })
      this.setState({ DoctorInfo: res[0].DoctorDegree })
      //  22-dec-2022 for groupTitle and image
      this.setState({groupTitle:res[0].group_title})
      this.setState({groupImage:res[0].image_base64})
    })

      
  }
 base64toBlob = (string) => {
   
    const bytes = atob(string);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
};
  svgString2Image=(svgString, width, height, format, callback)=>{
    
    var temp=document.getElementById('barcode').innerHTML
    // set default for format parameter
    format = format ? format : 'jpg';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp)));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
        // clear canvas
        context.clearRect(0, 0, width, height);
        // draw image with SVG data to canvas
        context.drawImage(image, 0, 0, width, height);
        // snapshot canvas as png
        var pngData = canvas.toDataURL('image/jpeg');
        // pass png data URL to callbac
        callback(pngData)
    }; // end async
    image.src=svgData
  
  }
  handleChange (html) {

    var manipulated_html=html.replaceAll('class="ql-size-small"','style="font-size:10px"')
    manipulated_html=manipulated_html.replaceAll('<p','<p style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll('<li','<li style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll('<ol','<ol style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll( '<br>','<p><span style="color: rgb(255, 255, 255);">Space</span></p>')
  
    document.getElementById("preview").innerHTML=manipulated_html
    console.log(manipulated_html)
  this.setState({ editorHtml: html });
  }
  
  handleThemeChange (newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }



generate(name,refBy,invoice_id,age_gender,Salutation,TestTitle, InvoiceDate, InvoiceTime,UserName, DoctorName,DoctorInfo,Ref_No,ReportDate,ReportTime,GroupTitleNew,imageCond)
{
  // image from props that are in base64 and dispaly in pdf 13-dec-22 && 22-dec Imagecond (image condition apply when image!="" then it will show )
 var tesTmg= "data:image/png;base64,"+`${this.state.groupImage}`;
  var state = this.state
  this.svgString2Image(this.props.barcode,800,600,'jpg',(barcode)=>{
      

	var pdf = new jsPDF('p', 'pt', 'a4');
    pdf.setFontSize(10);
    pdf.setFont('arial','normal')
    var elementHandler = {
      '#ignorePDF': function (element, renderer) {
        return true;
      }
    };
    pdf.fromHTML(document.getElementById('preview'), 
    70, // x coord
  // 20-may-2024 on demand of 0202425 by recption khizer ultrasound abdomen me name pe repoet ka content hai

    // 155,
    this.props.xRayHeight,

           {
               // y coord
               width: this.state.margins.width// max width of content on PDF,
               ,'elementHandlers': elementHandler

           },function(dispose) 
           
        {
         var totalpages=pdf.internal.getNumberOfPages()
         for(var i = totalpages; i >= 1; i--)
         {
           pdf.setPage(i);                            
             //header
             pdf.setFontStyle('normal');
            // 03-oct-2023 for show header footer
            // pdf.addImage(require(`./header.jpg`), 'PNG', 30, 15, 190,90); 

             //header Image
            //  if(imageCond !=""){  
            //   pdf.addImage(tesTmg, 'PNG', 250, 63, 100,63); 
            //   pdf.setFillColor(242, 240, 240);
            //   pdf.rect(250, 35, 100, 20, "F");
            //   pdf.rect(250, 35, 100, 20, "F");
            //   pdf.line(250,55,350,55) //Bottom
            //   pdf.line(250,35,350,35) // Top
            //   pdf.line(250,35,250,55) //Left
            //   pdf.line(350,35,350,55) //Right
            //  }
            //  else{
            //   pdf.setFillColor(242, 240, 240);
            //   pdf.rect(268, 50, 70, 18, "F");
            //   pdf.rect(268, 50, 70, 18, "F");
            //   pdf.line(268,68,338,68) //Bottom
            //   pdf.line(268,50,338,50) // Top
            //   pdf.line(268,50,268,68) //Left
            //   pdf.line(338,50,338,68) //Right
            //  }
             pdf.setFillColor(242, 240, 240);
             pdf.rect(268, 50, 70, 18, "F");
             pdf.rect(268, 50, 70, 18, "F");
             pdf.line(268,68,338,68) //Bottom
             pdf.line(268,50,338,50) // Top
             pdf.line(268,50,268,68) //Left
             pdf.line(338,50,338,68) //Right
           
             
            // MAIN HEADING
            pdf.setFontStyle('bold');
            pdf.setFontSize(18);
           
            if (state.testCode == "ECHO") {
              pdf.myText(TestTitle, { align: "center" }, 70, 120);
              
            } else {
              pdf.myText(TestTitle, { align: "center" }, 70, 150);
            }

           
             pdf.setFontStyle('bold');
             pdf.setFontSize(7.5);
      
             pdf.text("MPL ID : "+invoice_id, 275, 62 );
            // if(imageCond !=""){  
            //   pdf.setFontStyle('bold');
            //   pdf.setFontSize(9.0);
            //    pdf.text("MPL ID : "+invoice_id, 265, 48);
            //   }else{
            //        pdf.setFontStyle('bold');
            //    pdf.setFontSize(7.5);
            //    pdf.text("MPL ID : "+invoice_id, 275, 62 );
            //   }
             pdf.setFontSize(8.5);
             // Barcode Image
           pdf.addImage(barcode, 'JPG', 400, 10, 170,40); 
             pdf.text(Salutation+" "+name, 410, 60 );
             pdf.setLineWidth(0.1);
           

             //for testing
            //  pdf.line(410, 65, 560,65); // Name Line
            //  pdf.line(410, 80, 560,80); // Age Line
            //  pdf.line(410, 95, 560,95); // Ref By Line
            //  pdf.line(410, 110, 560,110); // Ref By Line
           
            //  if(Ref_No!=""){
            //   pdf.line(410, 125, 560,125); // Ref No By Line
            //  }
            pdf.line(410, 65, 560,65); // Name Line
            pdf.line(410, 80, 560,80); // Age Line
            pdf.line(410, 95, 560,95); // Ref By Line
            pdf.line(410, 110, 560,110); // Ref no Line
            // if(Ref_No!=""){
            //   pdf.line(410, 125, 560,125); // Ref No By Line
            //  }
             pdf.line(410, 125, 560,125); // Result By Line
           
            
             pdf.setFontSize(7.5);
             pdf.setFontStyle('bold');
             pdf.text("Age/Gender", 410, 75);
              pdf.setFontStyle('normal');
             pdf.text(":   "+age_gender, 470, 75);
            //  pdf.text("Age/Gender", 410, 82.5);
            //  pdf.text(":  "+age_gender, 465, 82.5);
             pdf.setFontStyle('bold');
             pdf.text("Ref By", 410, 90);
             pdf.setFontStyle('normal');
             pdf.text(":   "+refBy, 470, 90);
            
            
             console.log("Reference No")
             console.log(Ref_No)
             pdf.setFontStyle('bold');
              pdf.text("Reference No", 410, 105);
             pdf.setFontStyle('normal');
             if(Ref_No!=""){
              pdf.text(":   "+Ref_No, 470, 105);
             }else{
              pdf.text(":   Nil", 470, 105);
             }
             pdf.setFontStyle('bold');
               pdf.text("Result Date/Time", 410, 120);
             pdf.setFontStyle('normal');
             pdf.text(":   "+ReportDate+"  "+ReportTime, 470, 120);
            
            //  pdf.setFontSize(7.5);
            //  pdf.setFontStyle('normal');
            //  pdf.setFontStyle('bold');

            //  pdf.text("Age/Gender", 410, 75);
            //  pdf.setFontStyle('normal');

            //  pdf.text(":  "+age_gender, 465, 75);
            //  pdf.setFontStyle('bold');

            //  pdf.text("Result Date/Time", 410, 90);
            //  pdf.setFontStyle('normal');

            //  pdf.text(" :  "+ReportDate+" "+ReportTime, 465, 90);
            //  pdf.setFontStyle('bold');

            //  pdf.text("Reference By", 410, 105);
            //  pdf.setFontStyle('normal');

            //  pdf.text(":  "+refBy, 465, 105);
            //  console.log("Reference No")
            //  console.log(Ref_No)
            //  if(Ref_No!=""){
            //  pdf.setFontStyle('bold');

            //   pdf.text("Ref No", 410, 120);
            //  pdf.setFontStyle('normal');

            //   pdf.text(":  "+Ref_No, 465, 120);
            //  }
            //  if(GroupTitleNew !=""){
            //   pdf.setFontSize(9.2);
            //   pdf.setFontStyle('bold');
            //    //function call lkiya hai us me align start new dala hai es k lye 13-dec
            //    pdf.myText(GroupTitleNew,{align: "start"},35,134,1116); 
            //   //   pdf.text(GroupTitleNew, 35, 134);
            //   //   pdf.setLineWidth(0.5);
            //   // pdf.line(35,136,217,136) //Right)
            //  }

             if(state.testCode=="ECHO"){
              var head = [['Parameters', 'Value', 'Normal (mm)', 'Parameters','Value','Normal']]
              var body = [
                ['L.V End diastolic',state.LVEnddiastolic,'35 ~ 55','Aortic Peak Flow Velocity',state.AorticPeakFlowVelocity,'0.9-1.7(m/s)'],
                ['L.V End Systolic',state.LVEndSystolic,'25 ~ 41','Peak TR gradient',state.PeakTRgradient,''],		
                ['IVS Thickness (D)',state.IVSThickness,'6 ~ 11','Peak Aortic Gradient',state.PeakAorticGradient,'<10 mmHg'],
                ['LVPW Thickness (D)',state.LVPWThickness,'6 ~ 11','Aortic Area',state.AorticArea,'2 ~ 3 cm2'],
                ['Right Ventricle',state.RightVentricle,'9 ~ 25','Mitral Area',state.MitralArea,'3 cm2'],
                ['Right  Atrium(4C)',state.RightAtrium,'27 ~ 37','E/A Ratio',state.EARatio,'>1 ~ 2'], 
                ['Left Atrium',state.LeftAtrium,'19 ~ 40','IVRT',state.IVRT,'60 ~ 86 ms'],
                ['Aortic Root',state.AorticRoot,'20 ~ 37','Deceleration Time',state.DecelerationTime,'150 ~ 220 ms'],
                ['Aortic Valve Opening',state.AorticValveOpening,'15 ~ 27','Valvular Regurgitation',"",'Trace | Mild | Mod | Severe'],
                ['Fractional Shortening',state.FractionalShortening,'','Mitral Regurgitation ',state.MitralRegurgitation,''],

                ['Ejection Fraction',state.EjectionFraction,'55 ~ 70 %','Tricuspid Regurgitation',state.TricuspidRegurgitation,''],
                ['Pericardial Effusion',state.PericardialEffusion,'','Aortic Regurgitation',state.AorticRegurgitation,''],

                ['',"",'','Pulmonic Regurgitation',state.PulmonicRegurgitation,'']
                
              
              ]
              autoTable(pdf, {
                head: head,
                body: body,
                margin : {
                  top: 140
                },
                bodyStyles:{
                  fontSize:7,
                  color:"black"
                },
                headStyles:{
                 fillColor:"black"
               },
                didDrawCell: function (hookData) {
                  if (hookData.section === 'body') {
                    if (hookData.row.index === 8) {
                      for (const cell of Object.values(hookData.row.cells)) {
                        if(cell.text[0]=='Valvular Regurgitation' || cell.text[0]=='Trace | Mild | Mod | Severe'){
                          cell.styles.fontStyle = 'bold';
                        }
                      }
                    }
                  }
                }
              })
              head = [['INTERPRETATION:  ','Analysis']]
              body = [
                ['Left Ventricle',state.Inter_LeftVentricle],
                ['Right Ventricle',state.Inter_RightVentricle],
                ['Atria',state.Inter_Atria],
                ['Aortic Valve & Aortic Root',state.Inter_AorticValveAorticRoot],

                ['Mitral Valve',state.Inter_MitralValve],
                ['Tricuspid Valve',state.Inter_TricuspidValve],
                ['Pulmonary Valve',state.Inter_PulmonaryValve],
                ['Other Findings',state.Inter_OtherFindings],
               
  
              ]
              autoTable(pdf, {
                head: head,
                body: body,
                margin : {
                  top: 420
                },
                bodyStyles:{
                  fontSize:7,
                  color:"black"
                },
                headStyles:{
                 fillColor:"black"
               },
              })
           

             
              head = [['Conclusion:  ']]
              body = [
               [state.Conclusion],
               
               
  
              ]

              autoTable(pdf, {
                head: head,
                body: body,
              
                bodyStyles:{
                  fontStyle:"bold",
                  fontSize:7,
                  color:"black"
                 },
                 headStyles:{
                  fillColor:"black"
                },
 
                
              })
              pdf.setFontSize(8);
              // 20-oct-2023 thora niche kiya echo me 
              // pdf.text("This diagnostic procedure has been performed on state of the art Vivid T8 Echocardiography system by GE Healthcare.", 40, 690);

              if(state.Conclusion.includes("D SHAPED LV , DILATED RA RV"))
              pdf.text("This diagnostic procedure has been performed on state of the art Vivid T8 Echocardiography system by GE Healthcare.", 40, 713);
else
pdf.text("This diagnostic procedure has been performed on state of the art Vivid T8 Echocardiography system by GE Healthcare.", 40, 713);
  
            }
               // Signing Start 
               //comment copy from printing portal radiology
             //  pdf.internal.pageSize.getHeight()-120 3-feb-2023 thora oper kiya hai -120 se -125,-110 se -115 ,-100 se -105,90 se 95
              //  pdf.internal.pageSize.getHeight()-120 6-feb-2023 thora oper kiya hai -125 se --140,-115 se -130 ,-105 se -120,95 se 110 3001470 signature opr aa rhy the ab koi issue aaya to fontsize chota kr den ge ye yhan zrorat ni thi ye radiology portal me krna tha agr koi issue hua to ye remove kr k opr wala lga den ge
               pdf.setFontSize(7);
               pdf.text("User ID : "+UserName+" Inv. Date : "+InvoiceDate+" Inv. Time : "+InvoiceTime,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-120,"right");
               pdf.setFontStyle('bold');
               pdf.setFontSize(9);
  
               pdf.setTextColor(255,0,0)
               pdf.text("This is a digitally verified Report",pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-110,"right");
               pdf.setTextColor(0,0,0)
               pdf.text(DoctorName,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-100,"right");
               pdf.setFontStyle('normal');
               var margin=90
               var degree=DoctorInfo.split('|')
               degree.map((item)=>{
                 pdf.text(item,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-margin,"right");
                 margin=margin-10
               })
               // Signing End
               if(totalpages>1){
                pdf.text("Page "+i+" of "+totalpages,70 , pdf.internal.pageSize.getHeight()-110,"left");
               
               } 
             pdf.setLineCap(2);
             pdf.setLineCap(2);
             //footer
             // Footer
            //  pdf.addImage(require(`./FooterRadiologyNew1.jpg`), 'JPG', 0, pdf.internal.pageSize.getHeight()-50 , pdf.internal.pageSize.getWidth(),50); 

             
             
             pdf.page++;
           }
        }, 
       this.state.margins);
           this.setState({PurePDF:pdf.output('datauristring')})
    const blob = this.base64toBlob(pdf.output('datauristring').split('base64,')[1]);
    const url = URL.createObjectURL(blob);
       this.setState({pdfHtml:url})
  })
 
};

  render () {
    return (
      <Wrapper>
          <div id="preview" hidden>

</div>
          {this.state.loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
      <Card className="overflow-visible">
     
        <div style={{
          textAlign:'center',
        
        }}>  <Button variant="contained" color="primary" onClick={()=>{
            this.setState({pdfHtml:""})
            this.setState({PurePDF:""})
            
            this.generate(this.props.name,this.props.refBy,this.props.invoice_id,this.props.age_gender, this.props.Salutation,this.props.TestTitle, this.props.InvoiceDate,this.props.InvoiceTime,this.props.UserName,this.props.DoctorName,this.props.DoctorInfo, this.props.Ref_No, this.props.ReportDate,this.props.ReportTime,this.state.groupTitle,this.state.groupImage)
        }}>Generate PDF Preview</Button></div>
        <Grid container style={{
            height:"800px"
        }}>

         <div id="barcode" hidden>
        
         <Barcode value={this.props.invoice_id} displayValue={false} />
        </div>
       
       
        
         <Grid item lg={12} md={12}  style={{
              border:"1px solid black",
              background:"black",
              height:"800px"
          }}>
       {
              this.state.pdfHtml!="" ?
              <iframe src={this.state.PurePDF}  
              
              style={{
                  width:"100%",
                  height:"100%"
              }}
              allowfullscreen></iframe>
            
          
          :<div
          style={{
              alignItems: 'center',
              color:"white",
              border: '2px dashed rgba(255,255,255)',
              display: 'flex',
              fontSize: '2rem',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
          }}
      >
          Preview area
      </div>
  
          }
           </Grid>
        
      </Grid>
     
        </Card>
  }
    </Wrapper>
     )
  }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RadiologyPDF.modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': ['10px','12px'] }],
    [{ 'align': [] }],
  
    ['clean'] 
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
