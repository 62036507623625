import React ,{useEffect,Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import SigningHTMLEditor from './SigningHTMLEditor'
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';




import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import TextField from "@material-ui/core/TextField";


import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';

import Grid from '@material-ui/core/Grid';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';







function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


const columns = 
  [{ id: 'id', label: 'Invoice ID' },
  { id: 'patient_id', label: 'Patient ID' },
  { id: 'name', label: 'Patient Name' },
  { id: 'code', label: 'Test Code' },
  { id: 'title', label: 'Test Name' },
  { id: 'Age_gender', label: 'Age/Gender' },
  { id: 'report_time', label: 'Reporting Time' },
  { id: 'feed', label: 'Ready to Sign' }
]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
 return h + ":" + m + ":" + s;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
  fontSize:{
    fontSize:12
  }
});
function msToTime (seconds) {
  console.log("ResulSigning time coming from backEnd 105 ",seconds)

  var minutes = parseInt(seconds/60, 10);
  console.log("ResulSigning minutes 107 ",minutes)
  seconds = seconds%60;
  console.log("ResulSigning seconds 110 ",seconds)

  var hours = parseInt(minutes/60, 10);
  console.log("ResulSigning hours 113 ",hours)
  
  minutes = minutes%60;
  console.log("ResulSigning minutes 116 ",minutes)
  console.log("ResulSigning total time is 117 ",hours+":"+minutes+":"+"00")
  return hours+":"+minutes+":"+"00";
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function ResultSigning() {
  const classes = useStyles();
  var date=new Date()


  const [patient_invoice,setPatientInvoice]=React.useState(false)
  date.setDate(date.getDate() - 2);
  const [from,setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel,setPanel]=React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows]= React.useState([]);

  const [Test_Title,setTest_Title]=React.useState('')


  const [org_rows,setOrg_Rows]= React.useState([]);
  const [loading,setLoading]=React.useState(true)
  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
  
    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
  };
  const [covidButton,setCovidButton]=React.useState(false)

  const [panelList,setpanelList]=React.useState([])
  const [laborder,setLabOrder]=React.useState(false)
  const [show,setShow]=React.useState(false)
  const [patient_id,setPatientID]=React.useState("")
  const [invoice_id,setInvoiceID]=React.useState("")
  const [age_gender,setAgeGender]=React.useState("")
  const [report_time,setReportTime]=React.useState("")
  const [barcode,setBarcode]=React.useState("")
  const [name,setName]=React.useState('')
  const [RefBy,setRefBy]=React.useState('')
  const [Ref_no,setRef_no]=React.useState("")
  const [Salutation,setSalutation]=React.useState('')
  const [Invoice_Aux_ID,setInvoice_Aux_ID]=React.useState('')

  const [DoctorName,setDoctorName]=React.useState('')
  const [DoctorInfo,setDoctorInfo]=React.useState('')
  const [InvoiceDate,setInvoiceDate]=React.useState('')
  const [InvoiceTime,setInvoiceTime]=React.useState('')
  const [testCode,settestCode]=React.useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches,setBranches]=React.useState([])
  const [branch,setBranch]=React.useState("")
  const [selectBranch,setSelectBranch]=React.useState([])
  const [state, setState] = React.useState({
    status:'All',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {

   
   
    var SearchFormData = { 
      RegDateFrom: from.toISOString(), 
      RegDateTo: to.toISOString(), 
      vBranchID:"0",
      InvoiceID:""
     }
    var vSearchStr = JSON.stringify(SearchFormData);
  
    var url ="https://reports.mpl-labs.pk:8443/4DACTION/RadiologySigningTestsGetList"
  
    fetch(url,{
      method: 'POST',
      body:vSearchStr
    }).then((res)=>res.json())
.then((result)=>{

var the_rows=[]


result.map((data)=>{

var obj= {
id:data.Invoice_ID,
patient_id:data.Patient_ID,
name:data.Full_Name,
code:data.T_CODE,
title:data.T_TiTLE,
Age_gender:data.Age_gender,
report_time:data.Rep_TimeDate,
feed:<Button variant="contained" style={{
    backgroundColor:"yellow",
    fontWeight:"bold"
}} 
onClick={()=>{

  settestCode(data.T_CODE)

  setRef_no(data.Ref_no)
    setPatientID(data.Patient_ID)
    setInvoiceID(data.Invoice_ID)
    setAgeGender(data.Age_gender)
    setReportTime(data.Rep_TimeDate)
    setBarcode(data.BarCode)
    setRefBy(data.Doctor_Name)
    setName(data.Full_Name)
    setInvoice_Aux_ID(data.InV_AuX_ID)
    setSalutation(data.Salutation)
    setInvoiceDate(new Date(data.Booking_DATE).ddmmyyy())
    setInvoiceTime(msToTime(data.Booking_TiME))
    setTest_Title(data.T_TiTLE)

    setShow(true)
}}
>Ready to Sign</Button>
}
the_rows.push(obj)

})


setRows(the_rows)
setOrg_Rows(the_rows)
setLoading(false)
setPage(0)

})



  },[])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes(); 
    var hh = this.getHours();

    return [
        (hh > 9 ? '' : '0') + hh,
        (mm > 9 ? '' : '0') + mm
    ].join(':');
};

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
  return (
    <>
    <Paper className={classes.root}>
  <div style={{
    display:'flex',
    flex:"1",
  justifyContent:'space-between',
  paddingLeft:'3em',
  paddingRight:"3em",
  paddingTop:"1em"
  }}>
                
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
 <KeyboardDateTimePicker
        variant="inline"
        inputVariant="outlined"
        label="From"
        ampm={false}
        format="dd/MM/yyyy"
        value={from}

        InputAdornmentProps={{ position: "start" }}
        onChange={date=> {
          setFrom(date)}}
      />
      </MuiPickersUtilsProvider>
          
                 
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
 <KeyboardDateTimePicker
        variant="inline"
        inputVariant="outlined"
        label="To"
        ampm={false}
        format="dd/MM/yyyy"
 
        value={to}
        InputAdornmentProps={{ position: "start" }}
        onChange={date=> setTo(date)}
      />
      </MuiPickersUtilsProvider>
                  
            
            
             
                  <Button variant="contained" color="primary" disableElevation disabled={loading}
                  style={{
                    marginBottom:5
                  }}
                  onClick={()=>{
                 
                    setPage(0)
                   document.getElementById('search_data').value=""
                    setLoading(true)
                   
                    var  branchID=localStorage.getItem('branch_id')
                    var SearchFormData = { 
                      RegDateFrom: from.toISOString(), 
                      RegDateTo: to.toISOString(), 
                      vBranchID:"0",
                      InvoiceID:""
                     }
                    var vSearchStr = JSON.stringify(SearchFormData);
                  
                    var url ="https://reports.mpl-labs.pk:8443/4DACTION/RadiologySigningTestsGetList"
                  
                    fetch(url,{
                      method: 'POST',
                      'Content-Type':"application/json",
                      body:vSearchStr
                    }).then((res)=>res.json())
     .then((result)=>{
      
      var the_rows=[]
     
     
      result.map((data)=>{
       
        var obj= {
          id:data.Invoice_ID,
          patient_id:data.Patient_ID,
          name:data.Full_Name,
          code:data.T_CODE,
          title:data.T_TiTLE,
          Age_gender:data.Age_gender,
          report_time:data.Rep_TimeDate,
          feed:   <Button variant="contained" style={{
              background:"yellow"
          }} 
          onClick={()=>{

            settestCode(data.T_CODE)

            setRef_no(data.Ref_no)
            setPatientID(data.Patient_ID)
            setInvoiceID(data.Invoice_ID)
            setAgeGender(data.Age_gender)
            setReportTime(data.Rep_TimeDate)
            setBarcode(data.BarCode)
            setRefBy(data.Doctor_Name)
            setName(data.Full_Name)
            setTest_Title(data.T_TiTLE)
            setInvoice_Aux_ID(data.InV_AuX_ID)
            setSalutation(data.Salutation)
            setInvoiceDate(new Date(data.Booking_DATE).ddmmyyy())
            setInvoiceTime(msToTime(data.Booking_TiME))
        
            setShow(true)
          }}
       >Ready to Sign</Button>
      }
      the_rows.push(obj)
       
      })
     
      
      setRows(the_rows)
      setOrg_Rows(the_rows)
      setLoading(false)
      setPage(0)

     })
                    
                  }}
                  >
                    Seach<SearchIcon />
                  </Button>
                </div>
        <div align="right">
               
               <TextField id="standard-basic" id="search_data" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
             style={{maxWidth:'50em'}}
              fullWidth={true}
              
               onChange={async (e)=>{
                   var lab_tests=[]
                   var text=e.target.value
                  var data=await org_rows.map((item)=>{
                    if(item.id.toLowerCase().includes(text.toLowerCase())){
                      lab_tests.push(item)
                      return
                   
                  }
                 
                  if(item.patient_id.trim().toLowerCase().includes(text.trim().toLowerCase())){
                    lab_tests.push(item)
                    return
                 
                }
                if(item.name.toLowerCase().includes(text.toLowerCase())){
                  lab_tests.push(item)
                  return
               
              }
                      
                      return item
              
                  })
                  Promise.all(data).then((item)=>{
                   
                     setRows(lab_tests)
                     
                  })
                  
            }}              
               />
             
            
               </div>
               
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code+Math.random()} >
                {columns.map((column) => {
                  const value = row[column.id];
                  var status_color= value=='Just Booked' ? 'brown' : value=='Only Phlebotomy Done' ? 'red' :
value=='Lying Pending Results' ? 'purple' : value=='Results Done But not Signed' ? 'blue' : value=='Results Ready' ? 'green' :'' 

                  return (
                  <>
                      {status_color.trim()!='' ?  
                      <TableCell key={column.id} align={column.align} style={{backgroundColor:status_color, color:'white'}} >
                     <i><b>{value}</b></i>
                                      </TableCell>
                      :   <TableCell key={column.id} align={column.align} >
                      {value}
                                       </TableCell>}
                   </>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
  
  <Grid>
      <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={show}
                  TransitionComponent={Transition}
                  keepMounted
                  maxWidth={'xl'}
                  fullWidth={true}
                  onClose={() => setShow(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                        <Grid container style={{marginTop:'1em'}}>
               <h2>Report Signing</h2>  
               </Grid>
             <Grid container style={{marginTop:'1em'}}>
             {/*   inputProps={{style: {fontSize: 14}}} ye add kiya hai TextField me */}
               
    <Grid item lg={2} md={2}>
    <TextField
            id="outlined-required"
            label="Patient ID"
            variant="outlined"
            value={patient_id}
            inputProps={{style: {fontSize: 14}}}
            // className={classes.fontSize}
          />
    </Grid>
    <Grid item lg={2} md={2}>
    <TextField
            id="outlined-required"
            label="Invoice ID"
            variant="outlined"
            inputProps={{style: {fontSize: 14}}}
            value={invoice_id}
          />
    </Grid>
    <Grid item lg={2} md={2}>
    <TextField
            id="outlined-required"
            label="Name"
            variant="outlined"
            inputProps={{style: {fontSize: 14}}}
            value={name}
          />
    </Grid>
    <Grid item lg={2} md={2}>
    <TextField
            id="outlined-required"
            label="Age/Gender"
            variant="outlined"
            inputProps={{style: {fontSize: 14}}}
            value={age_gender}
          />
    </Grid>
    <Grid item lg={2} md={2}>
    <TextField
            id="outlined-required"
            label="Reporting Time"
            variant="outlined"
            inputProps={{style: {fontSize: 14}}}
            value={report_time}
          />
    </Grid>
    </Grid>
              
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                    
                  >
                    <center>
                  <Fragment>
       
       {
           show ?
           <SigningHTMLEditor TestTitle={Test_Title}   testCode={testCode} Salutation={Salutation} InvoiceDate={InvoiceDate} InvoiceTime={InvoiceTime}  patient_id={patient_id} invoice_id={invoice_id} age_gender={age_gender} report_time={report_time} barcode={barcode} name={name} Ref_No={Ref_no} refBy={RefBy} Invoice_Aux_ID={Invoice_Aux_ID}/>
       
           :null
       }  
        </Fragment>
        </center>
                   </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setShow(false)}
                      color="danger"
                      simple
                    >
                      <b>Close</b>
                    </Button>
                  </DialogActions>
                </Dialog>
        </Grid>
    </Paper>
    
   
  </>
  );
}
