import 'react-quill/dist/quill.snow.css';
import './editor.css';

import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import ReactQuill, { Quill } from 'react-quill';
import { Wrapper } from '../components';
import { Grid,Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import jsPDF from 'jspdf'
import PDFViewer from 'pdf-viewer-reactjs'
import { RenderPageProps, Viewer } from '@react-pdf-viewer/core';
import TextField from "@material-ui/core/TextField";
import header from './header.png'
import ReactLoading from 'react-loading';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Barcode from 'react-barcode'

import bwipjs from 'bwip-js';
import autoTable from 'jspdf-autotable';
import RadioGroup, { useRadioGroup } from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';



(function (API) {
  API.myText = function (txt, options, x, y) {
    options = options || {};
    /* Use the options align property to specify desired text alignment
     * Param x will be ignored if desired text alignment is 'center'.
     * Usage of options can easily extend the function to apply different text 
     * styles and sizes 
    */
    var x = 0
    if (options.align == "center") {
      // Get current font size
      var fontSize = this.internal.getFontSize();

      // Get page width
      var pageWidth = this.internal.pageSize.getWidth();
      console.log(pageWidth)

      // Get the actual text's width
      /* You multiply the unit width of your string by your font size and divide
       * by the internal scale factor. The division is necessary
       * for the case where you use units other than 'pt' in the constructor
       * of jsPDF.
      */
      var txtWidth = this.getStringUnitWidth(txt) * fontSize / this.internal.scaleFactor;
      console.log(txtWidth)
      // Calculate text's x coordinate
      x = (pageWidth - txtWidth) / 2;
    }
    this.line(x, 152, x + txtWidth, 152)
    // Draw text at x,y
    console.log(x)
    console.log(y)
    console.log(txt)
    this.text(txt, x, y);
  }
})(jsPDF.API);

export default class SigningHTMLEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testCode: "",
      editorHtml: '',
      theme: 'snow',
      pdf: "",
      zoom: 1,
      pdfHtml: "",
      DoctorName: "",
      DoctorInfo: "",
      PurePDF: "",
      loading: true,
      margins: {
        top: 160,
        bottom: 120,
        left: 70,
        width: 450
      }
      ,
      LVEndSystolic: "",
      LVEnddiastolic: "",
      IVSThickness: "",
      LVPWThickness: "",
      RightVentricle: "",
      LeftAtrium: "",
      RightAtrium: "",
      AorticArea: "",
      AorticRoot: "",
      AorticPeakFlowVelocity: "",
      AorticValveOpening: "",
      FractionalShortening: "",
      EjectionFraction: "",
      PericardialEffusion: "",
      PeakTRgradient: "",
      PeakAorticGradient: "",
      MitralArea: "",
      EARatio: "",
      IVRT: "",
      DecelerationTime: "",
      MitralRegurgitation: "Trace",
      TricuspidRegurgitation: "Trace",
      AorticRegurgitation: "Trace",
      PulmonicRegurgitation: "Trace",

      Inter_LeftVentricle: "Normal sized LV with good contractility, LVEF of %.",
      Inter_RightVentricle: "Is normal in size and function.",
      Inter_Atria: "Is normal in size and function.",
      Inter_AorticValveAorticRoot: "Structurally & functionally normal.",
      Inter_MitralValve: "Structurally & functionally normal.",
      Inter_TricuspidValve: "Structurally & functionally normal.",
      Inter_PulmonaryValve: "Structurally & functionally normal.",
      Inter_OtherFindings: "No pericardial effusion, thrombus, mass or vegetation seen. No cardiac shunt.",
      Conclusion: "Normal LV systolic function. Grade I diastolic dysfunction."
    }

    this.handleChange = this.handleChange.bind(this)
    this.generate = this.generate.bind(this)
    this.svgString2Image = this.svgString2Image.bind(this)
    this.base64toBlob = this.base64toBlob.bind(this)


  }
  componentDidMount() {
    this.setState({ testCode: this.props.testCode })
    var obj = {
      Invoice_Aux_ID: this.props.Invoice_Aux_ID + "",
      User_ID: localStorage.getItem("user_id"),
    }
    var url = "https://reports.mpl-labs.pk:8443/4DACTION/RadiologyTestsResultsViewModify"

    fetch(url, {
      method: 'POST',
      'Content-Type': "application/json",
      body: JSON.stringify(obj)
    }).then((res) => res.json()).then((res) => {


      if (this.props.testCode == "ECHO") {
        this.setState({ ...JSON.parse(res[0].HTML_Report) })
      } else {
        document.getElementById('preview').innerHTML = res[0].HTML_Report
        this.setState({ editorHtml: res[0].HTML_Report })
      }
      this.setState({ loading: false })
      this.setState({ DoctorName: res[0].User_DoctorName })
      this.setState({ DoctorInfo: res[0].DoctorDegree })
    })

  }
  base64toBlob = (string) => {

    const bytes = atob(string);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  svgString2Image = (svgString, width, height, format, callback) => {

    var temp = document.getElementById('barcode').innerHTML
    // set default for format parameter
    format = format ? format : 'jpg';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp)));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/jpeg');
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }
  handleChange(html) {

    var manipulated_html = html.replaceAll('class="ql-size-small"', 'style="font-size:10px"')
    manipulated_html = manipulated_html.replaceAll('<p', '<p style="font-size:16px" ')
    manipulated_html = manipulated_html.replaceAll('<li', '<li style="font-size:16px" ')
    manipulated_html = manipulated_html.replaceAll('<ol', '<ol style="font-size:16px" ')
    manipulated_html = manipulated_html.replaceAll('<br>', '<p><span style="color: rgb(255, 255, 255);">Space</span></p>')

    document.getElementById("preview").innerHTML = manipulated_html

    this.setState({ editorHtml: html });
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }



  generate(name, refBy, invoice_id, age_gender, Salutation, TestTitle, InvoiceDate, InvoiceTime, DoctorName, DoctorInfo, Ref_No) {


    this.svgString2Image(this.props.barcode, 800, 600, 'jpg', (barcode) => {
      var Uname = localStorage.getItem('name')
      var state = this.state
      const user_name = Uname.charAt(0).toUpperCase() + Uname.slice(1)

      var pdf = new jsPDF('p', 'pt', 'a4');
      pdf.setFontSize(10);
      pdf.setFont('arial', 'normal')
      var elementHandler = {
        '#ignorePDF': function (element, renderer) {
          return true;
        }
      };
      pdf.fromHTML(document.getElementById('preview'),
        70, // x coord
        155,
        {
          // y coord
          width: this.state.margins.width// max width of content on PDF,
          , 'elementHandlers': elementHandler

        }, function (dispose) {
          var totalpages = pdf.internal.getNumberOfPages()
          for (var i = totalpages; i >= 1; i--) {
            pdf.setPage(i);
            //header
            pdf.setFontStyle('normal');
            //header Image
            pdf.addImage(require(`./header30-07-24.jpeg`), 'PNG', 35, 20, 170, 80);
            pdf.setFillColor(242, 240, 240);
            pdf.rect(268, 50, 70, 18, "F");
            pdf.rect(268, 50, 70, 18, "F");
            pdf.line(268, 68, 338, 68) //Bottom
            pdf.line(268, 50, 338, 50) // Top
            pdf.line(268, 50, 268, 68) //Left
            pdf.line(338, 50, 338, 68) //Right

            pdf.setFontStyle('bold');
            pdf.setFontSize(18);

            if (state.testCode == "ECHO") {
              pdf.myText(TestTitle, { align: "center" }, 70, 130);
            } else {
              pdf.myText(TestTitle, { align: "center" }, 70, 150);
            }




            pdf.setFontStyle('bold');
            pdf.setFontSize(7.5);

            pdf.text("MPL ID : " + invoice_id, 275, 62);
            pdf.setFontSize(8.5);
            // Barcode Image
            pdf.addImage(barcode, 'JPG', 400, 10, 170, 40);
            pdf.text(Salutation + " " + name, 410, 60);
            pdf.setLineWidth(0.1);
            pdf.line(410, 70, 560, 70); // Name Line
            pdf.line(410, 90, 560, 90); // Age Line
            pdf.line(410, 110, 560, 110); // Ref By Line

            if (Ref_No != "") {
              pdf.line(410, 130, 560, 130); // Ref No By Line
            }

            pdf.setFontSize(7.5);
            pdf.setFontStyle('normal');
            pdf.text("Age/Gender", 410, 82.5);
            pdf.text(":  " + age_gender, 465, 82.5);
            pdf.text("Ref By", 410, 102.3);
            pdf.text(":  " + refBy, 465, 102.5);
            console.log("Reference No")
            console.log(Ref_No)
            if (Ref_No != "") {
              pdf.text("Ref No", 410, 121.3);
              pdf.text(":  " + Ref_No, 465, 121.5);
            }
            if(state.testCode=="ECHO"){
              var head = [['Parameters', 'Value', 'Normal (mm)', 'Parameters','Value','Normal']]
              var body = [
                ['L.V End diastolic',state.LVEnddiastolic,'35 ~ 55','Aortic Peak Flow Velocity',state.AorticPeakFlowVelocity,'0.9-1.7(m/s)'],
                ['L.V End Systolic',state.LVEndSystolic,'25 ~ 41','Peak TR gradient',state.PeakTRgradient,''],		
                ['IVS Thickness (D)',state.IVSThickness,'6 ~ 11','Peak Aortic Gradient',state.PeakAorticGradient,'<10 mmHg'],
                ['LVPW Thickness (D)',state.LVPWThickness,'6 ~ 11','Aortic Area',state.AorticArea,'2 ~ 3 cm2'],
                ['Right Ventricle',state.RightVentricle,'9 ~ 25','Mitral Area',state.MitralArea,'3 cm2'],
                ['Right  Atrium(4C)',state.RightAtrium,'27 ~ 37','E/A Ratio',state.EARatio,'>1 ~ 2'], 
                ['Left Atrium',state.LeftAtrium,'19 ~ 40','IVRT',state.IVRT,'60 ~ 86 ms'],
                ['Aortic Root',state.AorticRoot,'20 ~ 37','Deceleration Time',state.DecelerationTime,'150 ~ 220 ms'],
                ['Aortic Valve Opening',state.AorticValveOpening,'15 ~ 27','Valvular Regurgitation',"",'Trace | Mild | Mod | Severe'],
                ['Fractional Shortening',state.FractionalShortening,'','Mitral Regurgitation ',state.MitralRegurgitation,''],

                ['Ejection Fraction',state.EjectionFraction,'55 ~ 70 %','Tricuspid Regurgitation',state.TricuspidRegurgitation,''],
                ['Pericardial Effusion',state.PericardialEffusion,'','Aortic Regurgitation',state.AorticRegurgitation,''],

                ['',"",'','Pulmonic Regurgitation',state.PulmonicRegurgitation,'']
                
              
              ]
              autoTable(pdf, {
                head: head,
                body: body,
                margin : {
                  top: 140
                },
                bodyStyles:{
                  fontSize:8,
                  color:"black"
                },
                headStyles:{
                 fillColor:"black"
               },
                didDrawCell: function (hookData) {
                  if (hookData.section === 'body') {
                    if (hookData.row.index === 8) {
                      for (const cell of Object.values(hookData.row.cells)) {
                        if(cell.text[0]=='Valvular Regurgitation' || cell.text[0]=='Trace | Mild | Mod | Severe'){
                          cell.styles.fontStyle = 'bold';
                        }
                      }
                    }
                  }
                }

              })
              head = [['INTERPRETATION:  ','Analysis']]
              body = [
                ['Left Ventricle',state.Inter_LeftVentricle],
                ['Right Ventricle',state.Inter_RightVentricle],
                ['Atria',state.Inter_Atria],
                ['Aortic Valve & Aortic Root',state.Inter_AorticValveAorticRoot],

                ['Mitral Valve',state.Inter_MitralValve],
                ['Tricuspid Valve',state.Inter_TricuspidValve],
                ['Pulmonary Valve',state.Inter_PulmonaryValve],
                ['Other Findings',state.Inter_OtherFindings],
               
  
              ]
              autoTable(pdf, {
                head: head,
                body: body,
                margin : {
                  top: 420
                },
                bodyStyles:{
                  fontSize:8,
                  color:"black"
                 },
                 headStyles:{
                  fillColor:"black"
                },
              })
           

             
              head = [['Conclusion:  ']]
              body = [
               [state.Conclusion],
               
               
  
              ]

              autoTable(pdf, {
                head: head,
                body: body,
              
                bodyStyles:{
                  fontStyle:"bold",
                  fontSize:8,
                  color:"black"
                 },
                 headStyles:{
                  fillColor:"black"
                },
 
                
              })
              pdf.setFontSize(8);
              pdf.text("This diagnostic procedure has been performed on state of the art Vivid T8 Echocardiography system by GE Healthcare.", 40, 690);
            
  
            }
            // Signing Start

            pdf.setFontSize(7);
            pdf.text("User ID : " + user_name + " Inv. Date : " + InvoiceDate + " Inv. Time : " + InvoiceTime, pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 120, "right");
            pdf.setFontStyle('bold');
            pdf.setFontSize(9);

            pdf.setTextColor(255, 0, 0)
            pdf.text("This is a digitally verified Report", pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 110, "right");
            pdf.setTextColor(0, 0, 0)
            pdf.text(DoctorName, pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 100, "right");
            pdf.setFontStyle('normal');
            var margin = 90
            var degree = DoctorInfo.split('|')
            degree.map((item) => {
              pdf.text(item, pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - margin, "right");
              margin = margin - 10
            })
            // Signing End
            if (totalpages > 1) {
              pdf.text("Page " + i + " of " + totalpages, 40, pdf.internal.pageSize.getHeight() - 110, "left");

            }

            pdf.setLineCap(2);
            pdf.setLineCap(2);
            //footer
            // Footer
            // pdf.addImage(require(`./FooterRadiology.jpg`), 'JPG', 0, pdf.internal.pageSize.getHeight() - 50, pdf.internal.pageSize.getWidth(), 50);
            pdf.addImage(require(`./FooterRadiologyjuly30.jpeg`), 'JPG', 0, pdf.internal.pageSize.getHeight() - 70, pdf.internal.pageSize.getWidth(), 70);
          
            pdf.page++;
          }
        },
        this.state.margins);
      this.setState({ PurePDF: pdf.output('datauristring') })
      const blob = this.base64toBlob(pdf.output('datauristring').split('base64,')[1]);
      const url = URL.createObjectURL(blob);
      this.setState({ pdfHtml: url })
    })

  };

  render() {
    return (
      <Wrapper>
        <div id="preview" hidden={true}>

        </div>
        {this.state.loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <Card className="overflow-visible"
            style={{
              height: "700px"
            }}
          >

            <div style={{
              textAlign: 'right',

            }}>
              <Button>  <h4>{parseInt(this.state.zoom * 100) + "%"}</h4></Button>
              <Button onClick={() => {
                this.setState({ zoom: this.state.zoom + 0.1 })
                console.log(this.state.zoom)
              }}> <AddIcon /></Button>
              <Button onClick={() => {
                this.setState({ zoom: this.state.zoom - 0.1 })
                console.log(this.state.zoom)
              }}> <RemoveIcon /></Button>

              <Button variant="contained" color="primary" onClick={() => {
                this.setState({ pdfHtml: "" })
                this.setState({ PurePDF: "" })

                this.generate(this.props.name, this.props.refBy, this.props.invoice_id, this.props.age_gender, this.props.Salutation, this.props.TestTitle, this.props.InvoiceDate, this.props.InvoiceTime, this.state.DoctorName, this.state.DoctorInfo, this.props.Ref_No)
              }}>Generate PDF Preview</Button></div>
            <Grid container style={{
              height: "500px"
            }}>

              <Grid item lg={6} md={6} style={{
                border: "1px solid black",
                height: "10em",
                minHeight: "100%",
                overflow: 'auto'
              }}>
                {
                  this.state.testCode == "ECHO"
                    ?
                    <>
                      {/* Echo Variables */}
                      {/*  InputLabelProps={{style: {fontSize: 13}}}  ye add kiya hai */}
                      <Grid container style={{ marginTop: '1em' }}>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="L.V End diastolic"
                            variant="outlined"
                            value={this.state.LVEnddiastolic}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ LVEnddiastolic: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="L.V End Systolic"
                            variant="outlined"
                            value={this.state.LVEndSystolic}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ LVEndSystolic: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="IVS Thickness (D)"
                            variant="outlined"
                            value={this.state.IVSThickness}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ IVSThickness: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="LVPW Thickness (D)"
                            variant="outlined"
                            value={this.state.LVPWThickness}
                            InputLabelProps={{style: {fontSize: 13}}} 

                            onChange={(event) => {
                              this.setState({ LVPWThickness: event.target.value })
                            }}
                          />
                        </Grid>

                      </Grid>
                      {/* Echo Variables */}
                      {/* Echo Variables */}
                      <Grid container style={{ marginTop: '1em' }}>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Right Ventricle"
                            variant="outlined"
                            value={this.state.RightVentricle}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ RightVentricle: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Right  Atrium(4C)"
                            variant="outlined"
                            value={this.state.RightAtrium}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ RightAtrium: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Left Atrium"
                            variant="outlined"
                            value={this.state.LeftAtrium}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ LeftAtrium: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Aortic Root"
                            variant="outlined"
                            value={this.state.AorticRoot}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ AorticRoot: event.target.value })
                            }}
                          />
                        </Grid>

                      </Grid>
                      {/* Echo Variables */}
                      {/* Echo Variables */}
                      <Grid container style={{ marginTop: '1em' }}>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Aortic Valve Opening"
                            variant="outlined"
                            value={this.state.AorticValveOpening}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ AorticValveOpening: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Fractional Shortening"
                            variant="outlined"
                            value={this.state.FractionalShortening}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ FractionalShortening: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Ejection Fraction"
                            variant="outlined"
                            value={this.state.EjectionFraction}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ EjectionFraction: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Pericardial Effusion"
                            variant="outlined"
                            value={this.state.PericardialEffusion}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ PericardialEffusion: event.target.value })
                            }}
                          />
                        </Grid>

                      </Grid>
                      {/* Echo Variables */}
                      {/* Echo Variables */}
                      <Grid container style={{ marginTop: '1em' }}>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Aortic Peak Flow Velocity"
                            variant="outlined"
                            value={this.state.AorticPeakFlowVelocity}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ AorticPeakFlowVelocity: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Peak TR gradient"
                            variant="outlined"
                            value={this.state.PeakTRgradient}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ PeakTRgradient: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Peak Aortic Gradient"
                            variant="outlined"
                            value={this.state.PeakAorticGradient}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ PeakAorticGradient: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Aortic Area"
                            variant="outlined"
                            value={this.state.AorticArea}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ AorticArea: event.target.value })
                            }}
                          />
                        </Grid>

                      </Grid>
                      {/* Echo Variables */}
                      {/* Echo Variables */}
                      <Grid container style={{ marginTop: '1em' }}>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Mitral Area"
                            variant="outlined"
                            value={this.state.MitralArea}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ MitralArea: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="E/A Ratio"
                            variant="outlined"
                            value={this.state.EARatio}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ EARatio: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="IVRT"
                            variant="outlined"
                            value={this.state.IVRT}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ IVRT: event.target.value })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-required"
                            label="Deceleration Time"
                            variant="outlined"
                            value={this.state.DecelerationTime}
                            InputLabelProps={{style: {fontSize: 13}}} 
                            onChange={(event) => {
                              this.setState({ DecelerationTime: event.target.value })
                            }}
                          />
                        </Grid>

                      </Grid>
                      {/* Mitral Regurgitation */}
                      {/* label="Trace" ki jga ye add kiya hai label={<Box fontSize={14}>Trace</Box>} */}

                      <RadioGroup name="use-radio-group" defaultValue="first" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <h3>Mitral Regurgitation  </h3>
                        <FormControlLabel  value="Trace" label={<Box fontSize={14}>Trace</Box>}  control={<Radio />} checked={this.state.MitralRegurgitation === 'Trace'} onChange={(event) => { this.setState({ MitralRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mild" label={<Box fontSize={14}>Mild</Box>}  control={<Radio />} checked={this.state.MitralRegurgitation === 'Mild'} onChange={(event) => { this.setState({ MitralRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mod" label={<Box fontSize={14}>Mod</Box>} control={<Radio />} checked={this.state.MitralRegurgitation === 'Mod'} onChange={(event) => { this.setState({ MitralRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Severe" label={<Box fontSize={14}>Severe</Box>}  control={<Radio />} checked={this.state.MitralRegurgitation === 'Severe'} onChange={(event) => { this.setState({ MitralRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="None" label={<Box fontSize={14}>None</Box>}  control={<Radio />} checked={this.state.MitralRegurgitation === 'None'} onChange={(event) => { this.setState({ MitralRegurgitation: event.target.value }) }} />
                      
                      </RadioGroup>

                      {/* Tricuspid Regurgitation */}

                      <RadioGroup name="use-radio-group" defaultValue="first" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <h3>Tricuspid Regurgitation</h3>
                        <FormControlLabel value="Trace"label={<Box fontSize={14}>Trace</Box>}  control={<Radio />} checked={this.state.TricuspidRegurgitation === 'Trace'} onChange={(event) => { this.setState({ TricuspidRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mild" label={<Box fontSize={14}>Mild</Box>}  control={<Radio />} checked={this.state.TricuspidRegurgitation === 'Mild'} onChange={(event) => { this.setState({ TricuspidRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mod" label={<Box fontSize={14}>Mod</Box>} control={<Radio />} checked={this.state.TricuspidRegurgitation === 'Mod'} onChange={(event) => { this.setState({ TricuspidRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Severe" label={<Box fontSize={14}>Severe</Box>}  control={<Radio />} checked={this.state.TricuspidRegurgitation === 'Severe'} onChange={(event) => { this.setState({ TricuspidRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="None" label={<Box fontSize={14}>None</Box>} control={<Radio />} checked={this.state.TricuspidRegurgitation === 'None'} onChange={(event) => { this.setState({ TricuspidRegurgitation: event.target.value }) }} />
                   
                      </RadioGroup>

                      {/* Aortic Regurgitation */}

                      <RadioGroup name="use-radio-group" defaultValue="first" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <h3>Aortic Regurgitation  </h3>
                        <FormControlLabel value="Trace" label={<Box fontSize={14}>Trace</Box>}  control={<Radio />} checked={this.state.AorticRegurgitation === 'Trace'} onChange={(event) => { this.setState({ AorticRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mild" label={<Box fontSize={14}>Mild</Box>}  control={<Radio />} checked={this.state.AorticRegurgitation === 'Mild'} onChange={(event) => { this.setState({ AorticRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mod" label={<Box fontSize={14}>Mod</Box>} control={<Radio />} checked={this.state.AorticRegurgitation === 'Mod'} onChange={(event) => { this.setState({ AorticRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Severe" label={<Box fontSize={14}>Severe</Box>}  control={<Radio />} checked={this.state.AorticRegurgitation === 'Severe'} onChange={(event) => { this.setState({ AorticRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="None" label={<Box fontSize={14}>None</Box>} control={<Radio />} checked={this.state.AorticRegurgitation === 'None'} onChange={(event) => { this.setState({ AorticRegurgitation: event.target.value }) }} />
                   
                      </RadioGroup>


                      {/* Pulmonic Regurgitation */}

                      <RadioGroup name="use-radio-group" defaultValue="first" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <h3>Pulmonic Regurgitation</h3>
                        <FormControlLabel value="Trace" label={<Box fontSize={14}>Trace</Box>}  control={<Radio />} checked={this.state.PulmonicRegurgitation === 'Trace'} onChange={(event) => { this.setState({ PulmonicRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mild"label={<Box fontSize={14}>Mild</Box>} control={<Radio />} checked={this.state.PulmonicRegurgitation === 'Mild'} onChange={(event) => { this.setState({ PulmonicRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Mod" label={<Box fontSize={14}>Mod</Box>} control={<Radio />} checked={this.state.PulmonicRegurgitation === 'Mod'} onChange={(event) => { this.setState({ PulmonicRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="Severe" label={<Box fontSize={14}>Severe</Box>}  control={<Radio />} checked={this.state.PulmonicRegurgitation === 'Severe'} onChange={(event) => { this.setState({ PulmonicRegurgitation: event.target.value }) }} />
                        <FormControlLabel value="None" label={<Box fontSize={14}>None</Box>} control={<Radio />} checked={this.state.PulmonicRegurgitation === 'None'} onChange={(event) => { this.setState({ PulmonicRegurgitation: event.target.value }) }} />
                   
                      </RadioGroup>


                      {/* Echo Variables */}

                      <Grid container style={{ marginTop: '1em' }}>
                        <Grid item lg={3} md={3}><h3>INTERPRETATION:</h3></Grid>
                        <Grid item lg={9} md={9}></Grid>
                        <Grid item lg={3} md={3}>


                          <TextField
                            id="outlined-multiline-static"
                            label="Left Ventricle"
                            multiline
                            value={this.state.Inter_LeftVentricle}
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            onChange={(event) => {
                              this.setState({ Inter_LeftVentricle: event.target.value })
                            }}
                            defaultValue="" />

                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Right Ventricle"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            value={this.state.Inter_RightVentricle}
                            onChange={(event) => {
                              this.setState({ Inter_RightVentricle: event.target.value })
                            }}
                            defaultValue="" />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Atria"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            value={this.state.Inter_Atria}
                            onChange={(event) => {
                              this.setState({ Inter_Atria: event.target.value })
                            }}
                            defaultValue="" />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Aortic Valve & Aortic Root"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            value={this.state.Inter_AorticValveAorticRoot}
                            onChange={(event) => {
                              this.setState({ Inter_AorticValveAorticRoot: event.target.value })
                            }}
                            defaultValue="" />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Mitral Valve"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            value={this.state.Inter_MitralValve}
                            onChange={(event) => {
                              this.setState({ Inter_MitralValve: event.target.value })
                            }}
                            defaultValue="" />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Tricuspid Valve"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}}
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            value={this.state.Inter_TricuspidValve}
                            onChange={(event) => {
                              this.setState({ Inter_TricuspidValve: event.target.value })
                            }}
                            defaultValue="" />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Pulmonary Valve"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            onChange={(event) => {
                              this.setState({ Inter_PulmonaryValve: event.target.value })
                            }}
                            value={this.state.Inter_PulmonaryValve}
                            defaultValue="" />
                        </Grid>
                        <Grid item lg={3} md={3}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Other Findings"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              marginTop: 10
                            }}
                            onChange={(event) => {
                              this.setState({ Inter_OtherFindings: event.target.value })
                            }}
                            value={this.state.Inter_OtherFindings}
                            defaultValue="" />
                        </Grid>
                        <Grid item lg={8} md={8}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Conclusion"
                            multiline
                            rows={4}
                            InputLabelProps={{style: {fontSize: 13}}} 
            inputProps={{style: {fontSize: 14}}}

                            style={{
                              width: 500,
                              maxWidth: "100%",
                              marginTop: 10
                            }}
                            onChange={(event) => {
                              this.setState({ Conclusion: event.target.value })
                            }}
                            value={this.state.Conclusion}
                            defaultValue="" />
                        </Grid>

                      </Grid>

                    </>
                    :
                    <ReactQuill
                      theme={this.state.theme}
                      onChange={this.handleChange}
                      value={this.state.editorHtml}
                      modules={SigningHTMLEditor.modules}
                      formats={SigningHTMLEditor.formats}
                      placeholder={'Write something'}
                      bounds={'.app'}
                      placeholder={this.props.placeholder}
                    />
                }

              </Grid>
              <div id="barcode" hidden>

                <Barcode value={this.props.invoice_id} displayValue={false} />
              </div>



              <Grid item lg={6} md={6} style={{
                border: "1px solid black",
                background: "black",
                height: "600px"
              }}>
                {
                  this.state.pdfHtml != "" ?
                  <>
                       {/* embed working fine and also ifram but Viewer not showing pdf */}
                {/* <embed src={this.state.pdfHtml+"#toolbar=0 "}   style={{
                  width:"100%",
                  height:"100%"
              }}></embed> */}
                  <iframe src={this.state.pdfHtml+"#toolbar=0 "} 
                  //  allowfullscreen
                   frameborder="0" 
              
              style={{
                  width:"100%",
                  height:"100%"
              }}
             ></iframe>
                    {/* <Viewer
                      fileUrl={this.state.pdfHtml}
                      defaultScale={this.state.zoom}

                    /> */}
                    </>
                    : <div
                      style={{
                        alignItems: 'center',
                        color: "white",
                        border: '2px dashed rgba(255,255,255)',
                        display: 'flex',
                        fontSize: '2rem',
                        height: '100%',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      Preview area
                    </div>

                }
              </Grid>

            </Grid>
            <div style={{
              textAlign: 'left',
              padding: '2em',

            }}>

              <Button variant="contained" color="secondary"

                style={{
                  marginRight: '2em'
                }}
                onClick={() => {
                  if (this.state.editorHtml.trim() == '' && this.state.testCode != 'ECHO') {
                    alert("Can't Save Empty Report")
                    return
                  }
                  if (this.state.PurePDF == "") {
                    alert("Please Preview before Save or Finalize")
                    return
                  }
                  var r = window.confirm("Are you Sure, To Save Feed Test!");
                  if (r == true) {
                    var obj = {
                      Invoice_Aux_ID: this.props.Invoice_Aux_ID + "",
                      User_ID: localStorage.getItem("user_id"),
                      HTML_Report: this.state.testCode == 'ECHO' ? JSON.stringify({
                        ...this.state,
                        PurePDF: "",
                        Templates: "",
                        pdf: "",
                        editorHtml: ""
                      }) : document.getElementById("preview").innerHTML,
                      PDF_Report: this.state.PurePDF
                    }
                    var url = "https://reports.mpl-labs.pk:8443/4DACTION/RadiologyTestsResultsStoringWS"

                    fetch(url, {
                      method: 'POST',
                      'Content-Type': "application/json",
                      body: JSON.stringify(obj)
                    }).then((res) => res.json()).then((res) => {
                      if (res[0].Invoice_Status == "Successful") {
                        alert("Invoice No : " + res[0].Invoice_No + " , Result Feed Successfully")
                        window.location.reload()
                        return
                      } else {
                        alert("Sorry, Can't Feed Result")
                        return
                      }
                    })
                  } else {
                    console.log("cancel")
                  }



                }}>Save as Feed Result</Button>

              <Button variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  fontSize: '1em'
                }}
                onClick={() => {
                  if (this.state.editorHtml.trim() == '' && this.state.testCode != 'ECHO') {
                    alert("Can't Save Empty Report")
                    return
                  }
                  if (this.state.PurePDF == "") {
                    alert("Please Preview before Save or Finalize")
                    return
                  }
                  var r = window.confirm("Are you Sure, To Finzalize the Report!");
                  if (r == true) {
                    var obj = {
                      Invoice_Aux_ID: this.props.Invoice_Aux_ID + "",
                      User_ID: localStorage.getItem("user_id"),
                      HTML_Report: this.state.testCode == 'ECHO' ? JSON.stringify({
                        ...this.state,
                        PurePDF: "",
                        Templates: "",
                        pdf: "",
                        editorHtml: ""
                      }) : document.getElementById("preview").innerHTML,
                      PDF_Report: this.state.PurePDF,
                      SigningUpdateStatus: true,
                    }
                    var url = "https://reports.mpl-labs.pk:8443/4DACTION/RadiologyFinalizationStoringWS"

                    fetch(url, {
                      method: 'POST',
                      'Content-Type': "application/json",
                      body: JSON.stringify(obj)
                    }).then((res) => res.json()).then((res) => {
                      if (res[0].Invoice_Status == "Successful") {
                        alert("Invoice No : " + res[0].Invoice_No + " , Finalize Successfully")
                        window.location.reload()
                        return
                      } else {
                        alert("Sorry, Can't Feed Result")
                        return
                      }
                    })
                  } else {
                    console.log("cancel")
                  }



                }}>Finalize the Report</Button>
            </div>
          </Card>
        }
      </Wrapper>
    )
  }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
SigningHTMLEditor.modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': ['10px', '12px'] }],
    [{ 'align': [] }],

    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
