import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
// import { isMobile } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';

import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';



import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const columns =
  [
  { id: 'invoice_no', label: 'Invoice ID' },
  { id: 'patient_id', label: 'Patient ID' },
  { id: 'patient_name', label: 'Patient Name' },
  { id: 'branch_name', label: 'Branch' },
  { id: 'tests_included', label: 'Tests' },
  { id: 'mobile_no', label: 'Mobile No' },
  // { id: 'remarks', label: 'Remarks' },
  // { id: 'barcode', label: <p style={{
  //   textAlign:'center'
  // }}>Barcode (Branches)</p> },
  {id:'barcodeSticker' , label:'Print Report Sticker'},

  ]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(seconds) {

  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')
  date.setDate(date.getDate() - 2);

  const [selected, setSelected] = React.useState({
    reports: []
  })

 

  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  //for Edit Patient 11-jan-2023
  



  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)

  const [covidButton, setCovidButton] = React.useState(false)

  const [panelList, setpanelList] = React.useState([])
  const [laborder, setLabOrder] = React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [selectBranch, setSelectBranch] = React.useState([])
  const [selectOrders, setSelectedOrders] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All',
  });


  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const numberWithCommas= (x)=> {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }
    if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk"
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
      setBranches(response)

    })
    var patient_id = localStorage.getItem('patient_id')
    var role = localStorage.getItem('role')

    var from = new Date();


    var branchID = localStorage.getItem('branch_id')

    var to = new Date();
    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
    
    var url = "https://reports.mpl-labs.pk:8443/4DACTION/InvoiceListCalledByPrintPortal"
   
    to.setHours(15)
    from.setHours(15)
    fetch(url,{
      method:"POST",
      body:JSON.stringify({
        branch_id:1,
        date_start:from.toISOString(),
        date_end:to.toISOString()
      })
    }).then((res) => res.json())
      .then((result) => {
       console.log("Invoice Record 283 :",result)
        var the_rows = []
        //################ response of Api result Map (data) start ####################
        result.map((data) => {
          var br_title = ""

          if (data.branch_name == "METROPOLE") {
            br_title = "Islamabad"
          } else {
            br_title = data.branch_name.split('MPL,')[1]
          }
          //ye wala object niche the-rows me push krty hn aur table me show krty hn if 100 vale in result then 100 obj will be push on the rows
          var obj = {
            ...data,
            tests_included:data.tests_included.includes('^') ? data.tests_included.replaceAll('^','(').replaceAll('|',"),")+")" : data.tests_included.replaceAll('^','(').replaceAll('|',"),") ,
   
            barcode: <center>
            <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.invoice_no} disableElevation onClick={() => {
            
                var obj1 = {
                  sample_id: data.invoice_no,
                  age_gender: "",
                  test_title: "Nasal Swab/Nasal Secretions",
                  date: new Date().ddmmyyy(),
                  mpl_id: data.invoice_no,
                  name: data.patient_name,
                  patient_id: data.patient_id,
                  time: formatAMPM(new Date())

                }
                console.log("ref no is #######",JSON.stringify(data))
                // ye commit tha abi on kr dya hai 5 jan ko nxt update me done ho jae ga
                var url = "http://localhost:1000/GETBarcode"
                fetch(url, {
                  method: "POST",
                  body: JSON.stringify(obj1),
                  headers: {
                    'Content-Type': "application/json"
                  }
                }).then(() => {
                  alert("Print Request Sent")
                })
             
            }} >
              Barcode
          </Button>
          </center>
          ,
 

  //09-march-2023
  barcodeSticker:<center>
  <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={data.Invoice_ID} disableElevation onClick={()=>{
    var temp = {
      Invoice_No:data.invoice_no+""
    }
    fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
      method: 'POST',
      'Content-Type': "application/json",
      body: JSON.stringify(temp)
    }).then((res) => res.json()).then(async (record) => {
      // console.log("/Web_InvoiceAndLabOrderPrinting record"+JSON.stringify(record))
      var TestName=JSON.parse(record[0].Array_Test_Name)
      var obj1={
        name:data.patient_name,
        age_gender:record[0].Age_Gender,
        mpl_id:data.invoice_no,
        ref_by:record[0].Ref_By,
        date:new Date().ddmmyyy(),
        // study:data.tests_included
    study:TestName[0]


      }
        console.log(obj1)
        var url="http://localhost:1000/ReportSticker"
        fetch(url,{
          method:"POST",
          body:JSON.stringify(obj1),
          headers:{
            'Content-Type':"application/json"
          }
        }).then(()=>{
          alert("Print Request Sent")
        })
      })
    
    }}
    
    >
      <  PrintIcon  />
Report Sticker
    </Button>
    </center>,

          }
          the_rows.push(obj)

        })
        //################ response of Api result Map (data) end ####################
     
        setRows(the_rows)
        setOrg_Rows(the_rows)
        setLoading(false)
      }).catch(()=>{
        setLoading(false)
        setPage(0)
      })
    
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
      .then((result) => {
        setpanelList(result)
      })
    if (localStorage.getItem('org_branch_id') == '0') {
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        setBranches(response)
        var obj = {}
        response.map((item) => {
          obj[item.BranchID] = item.BranchName
        })
        setSelectBranch(obj)

        setBranch(localStorage.getItem('branch_id'))
      })
    }


  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>
        {/* <div style={{ padding: '1em', textAlign: 'center' }}>
          {localStorage.getItem('org_branch_id') == '0' ? <h3 style={{ color: 'red' }}>Branch Selection will change the Booking, Invoicing and Reporting Branch</h3> : null}
        </div> */}
        <GridContainer style={{ padding: '2em' }}>
          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="dd/MM/yyyy"
                value={from}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setFrom(date)}
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }

          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                format="dd/MM/yyyy"
                value={to}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setTo(date)}
              />
            </MuiPickersUtilsProvider>

          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }
          <GridItem xs={6} sm={6} md={2} lg={2}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
              <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={state.status}
                onChange={handleChange}
                inputProps={{
                  name: 'status',
                  id: 'filled-age-native-simple',
                }}
              >
                <MenuItem aria-label="Select" value="All" >All</MenuItem>
                <MenuItem value={'Just Booked'}>Just Booked</MenuItem>
                <MenuItem value={'Only Phlebotomy Done'}>Only Phlebotomy Done</MenuItem>
                <MenuItem value={'Lying Pending Results'}>Lying Pending Results</MenuItem>
                <MenuItem value={'Results Done But not Signed'}>Results Done But not Signed</MenuItem>
                <MenuItem value={'Results Ready'}>Results Ready</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          {
            localStorage.getItem('org_branch_id') == '0' ?
              <GridItem xs={12} sm={12} md={2} lg={2}  >
                <FormControl variant="filled" className={classes.formControl} style={{ width: "10em" }} >
                  {/* <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={branch}
                    onChange={(e) => {
                      var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                      if (result) {
                        setBranch(e.target.value)
                        localStorage.setItem('branch_id', e.target.value)
                        localStorage.setItem('branch_name', selectBranch[e.target.value])
                        window.location.reload()
                      }

                    }}
                  >
                    <MenuItem value={"0"} disabled>Select</MenuItem>
                    {
                      branches.map((item) => {
                        return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                      })
                    }
                  </Select> */}
                </FormControl>
              </GridItem>
              : null
          }
          <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto' }} >

            <Button variant="contained" color="primary" disableElevation disabled={loading}
              onClick={() => {
                setPage(0)
                document.getElementById('search_data').value = ""
                var role = localStorage.getItem('role')
                var patient_id = localStorage.getItem('patient_id')
                setLoading(true)
// local storage se Branch id aa rhi thi agr branch hui to invoicing portal se yhan copy paste kr den jaise whan branch aa rhi yhan b useeffect me bydefault set kr den phr yhan option de den ge 
                var branchID = 1
                var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
                var vSearchStr = JSON.stringify(SearchFormData);

                var url = "https://reports.mpl-labs.pk:8443/4DACTION/InvoiceListCalledByPrintPortal"


                to.setHours(15)
                from.setHours(15)
                
                fetch(url,{
                  method:"POST",
                  body:JSON.stringify({
                    branch_id:JSON.parse(branchID),
                    date_start:from.toISOString(),
                    date_end:to.toISOString()
                  })
                }).then((res) => res.json())
                  .then((result) => {
                    var the_rows = []
                    result.map((data) => {
                      var br_title = ""
            
                      if (data.branch_name == "METROPOLE") {
                        br_title = "Islamabad"
                      } else {
            
                        br_title = data.branch_name.split('MPL,')[1]
            
            
                      }
                      var obj = {
                        ...data,
                        tests_included:data.tests_included.includes('^') ? data.tests_included.replaceAll('^','(').replaceAll('|',"),")+")" : data.tests_included.replaceAll('^','(').replaceAll('|',"),") ,
                        barcode: <center>
                        <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.invoice_no} disableElevation onClick={() => {
                         
                            var obj1 = {
                              sample_id: data.invoice_no,
                              age_gender: "",
                              test_title: "Nasal Swab/Nasal Secretions",
                              date: new Date().ddmmyyy(),
                              mpl_id: data.invoice_no,
                              name: data.patient_name,
                              patient_id: data.patient_id,
                              time: formatAMPM(new Date()),
            
            
                            }
                            var url = "http://localhost:1000/GETBarcode"
                            fetch(url, {
                              method: "POST",
                              body: JSON.stringify(obj1),
                              headers: {
                                'Content-Type': "application/json"
                              }
                            }).then(() => {
                              alert("Print Request Sent")
                            })
                          
                        }} >
                          Barcode
            </Button>
                      </center>
                      ,
  barcodeSticker:<center>
  <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={data.Invoice_ID} disableElevation onClick={()=>{
    var temp = {
      Invoice_No:data.invoice_no+""
    }
    fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
      method: 'POST',
      'Content-Type': "application/json",
      body: JSON.stringify(temp)
    }).then((res) => res.json()).then(async (record) => {
      // console.log("/Web_InvoiceAndLabOrderPrinting record"+JSON.stringify(record))
      //study:JSON.parse(record[0].Array_Test_Name)
      var TestName=JSON.parse(record[0].Array_Test_Name)
        var obj1={
          name:data.patient_name,
          age_gender:record[0].Age_Gender,
          mpl_id:data.invoice_no,
          ref_by:record[0].Ref_By,
          date:new Date().ddmmyyy(),
          // study:data.tests_included
      study:TestName[0]


        }
        console.log(obj1)
        var url="http://localhost:1000/ReportSticker"
        fetch(url,{
          method:"POST",
          body:JSON.stringify(obj1),
          headers:{
            'Content-Type':"application/json"
          }
        }).then(()=>{
          alert("Print Request Sent")
        })
      })
    
    }}
    
    >
Report Sticker
    </Button>
    </center>,
                      }
                      the_rows.push(obj)
            
                    })

                    setRows(the_rows)
                    setOrg_Rows(the_rows)
                    setLoading(false)
                    setPage(0)
                  }).catch(()=>{
                    setLoading(false)
                    setPage(0)
                  })

              }}
            >
              Search<SearchIcon />
            </Button>

          </GridItem>
        </GridContainer>
        <div align="right">

          <TextField id="standard-basic" id="search_data" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
            style={{ maxWidth: '50em' }}
            fullWidth={true}

            onChange={async (e) => {
              var lab_tests = []
              var text = e.target.value
              var data = await org_rows.map((item) => {
            
                if (item.mobile_no.includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.patient_name.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.patient_id.trim().toLowerCase().includes(text.trim().toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.invoice_no.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.remarks.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.tests_included.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                return item

              })
              Promise.all(data).then((item) => {

                setRows(lab_tests)

              })

            }}
          />

        </div>
        {loading  ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          var status_color = value == 'Just Booked' ? 'brown' : value == 'Only Phlebotomy Done' ? 'red' :
                            value == 'Lying Pending Results' ? 'purple' : value == 'Results Done But not Signed' ? 'blue' : value == 'Results Ready' ? 'green' : ''

                          return (
                            <>
                              {status_color.trim() != '' ?
                                <TableCell key={column.id} align={column.align} style={{ backgroundColor: status_color, color: 'white' }} >
                                  <i><b>{value}</b></i>
                                </TableCell>
                                : <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }


      </Paper>

    </>
  );
}
