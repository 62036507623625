import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import InvoiceItemsTable from './InvoiceItemsTable'

import logo from '../blank_header.png'

import footer from '../blank_footer.png'
import InvoiceTitle from './InvoiceTitle'

import liver from '../Liver.jpg'
import ultrasound from '../Ultrasound.jpg'


Font.register({ family: 'Roboto', src: require('../../fonts/Roboto.ttf'), });

const styles = StyleSheet.create({
    page: {
        paddingTop: 130,
        paddingBottom: 100,
        fontSize: 8,
        fontFamily: "Helvetica",
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    qrcode: {
        width: 60,
        height: 60
    },
    logo: {
        width: 200,
        height: 100
    },
    barcode: {
        width: 130,
        height: 30,
    }
});


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function Age(day,month,year){
    var dob = new Date(month+"/"+day+"/"+year);  
    //calculate month difference from current date in time  
    var month_diff = Date.now() - dob.getTime();  
      
    //convert the calculated difference in date format  
    var age_dt = new Date(month_diff);   
      
    //extract year from date      
    var year = age_dt.getUTCFullYear();  
      
    //now calculate the age of the user  
    var age = Math.abs(year - 1970); 
    return age
  }
const Report = ({ invoice }) => {
    console.log(invoice)
    const [numPages, setNumPages] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(null)
    var day=invoice["D.O.B."].split(" ")[0].split("-")[2]
    var month=invoice["D.O.B."].split(" ")[0].split("-")[1]
    var year=invoice["D.O.B."].split(" ")[0].split("-")[0]

    var Curr_day=invoice["Time"].split(" ")[0].split("-")[2]
    var Curr_month=invoice["Time"].split(" ")[0].split("-")[1]
    var Curr_year=invoice["Time"].split(" ")[0].split("-")[0]
    var BMI= (invoice['Weight(kg)'] / ((invoice['Height(cm)'] * invoice['Height(cm)']) 
    / 10000)).toFixed(2);
 

    return (<Document>
    <Page
                        style={{
                            paddingTop: 135,
                            paddingBottom: 140,
                            fontSize: 8,
                            paddingLeft: 40,
                            paddingRight: 20,
                            lineHeight: 1.5
                        }}
                        size="A4"
                        wrap={true}
                    >
                        <View fixed style={{
                            width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                            paddingLeft: 20,
                            alignItems: "center",
                            fontFamily: "Helvetica",
                            paddingRight: 20,
                            paddingTop: 10
                        }} >
                            <Image style={styles.logo} src={logo} />

                            <View style={{ marginLeft: 117,marginTop:-10, flexDirection: "column", alignItems: "center" }}>
                                <View style={{
                                    margin: 'auto', width: 80, 
                                    backgroundColor: '#F2F0F0',
                                    borderWidth: 1,
                                    borderColor: 'black',
                                    marginTop: 20
                                }}>
                                    <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 8.5 }}>MPL ID : {invoice.invoice_id}</Text>
                                </View>


                            </View>
                            <View style={{ marginLeft: 50, marginTop: 5 }}  >


                            <Text style={{
                        marginLeft: '5em', 
                        fontFamily: 'Helvetica-Bold' ,
                        fontSize: 9, 
                        width: "170em",
                        marginTop:5,
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.3,
                    }}>{invoice.Note}</Text>
                                <View style={{
                                  marginLeft: '5em',
                                  paddingTop: '5em',
                                  fontSize: 7,
                                  flexDirection: "row",
                                  width: "170em",
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  borderBottomColor: 'black',
                                  borderBottomWidth: 0.3
                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Age/Gender</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{Age(day,month,year)+" / "+invoice["Gender"]}</Text>
                                </View>
                              
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Date/Time</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{Curr_day+"/"+Curr_month+"/"+Curr_year+" / "+invoice["Time"].split(' ')[1].split(".")[0]}</Text>
                                </View>
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Height</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice['Height(cm)']+" cm"}</Text>
                                </View>
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Weight</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice['Weight(kg)']+" kg"}</Text>
                                </View>
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>BMI</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{BMI}</Text>
                                </View>

                            </View>


                        </View>
                        <View  style={{
    marginTop:-50
  }}>
               
               <View style={{
                     flexDirection: 'row',
                     alignItems: 'center',
                     width: "100%",
                     fontSize: 7,
                     paddingLeft: 10,
                     paddingRight: 10
                   }}
                   >
                 
                 
                </View>
             <View style={styles.tableContainer} >
             
               <View style={{
                 padding: 10,
             
               }}>            
               <InvoiceTitle title={"Fibroscan Report"} />
              
               <View >
               <View style={{
                         flexDirection: 'row',
                         alignItems: 'center',
                         width: "100%",
                         fontSize: 7,
                         marginTop: 0.5,
                         borderWidth: 0.5
                       }} >
                          <View style={{
                            width:"20%"
                          }}></View>
                         <View style={{
                            width:"60%"
                          }}>
                         <Image style={{
                          width: "100%",
                          height: 160
                       }} src={"data:image/jpg;base64,"+invoice.image} />
             
                       </View>
                       <View style={{
                            width:"20%"
                          }}></View>
                         </View>
             
                       <View style={{
                         flexDirection: 'row',
                         alignItems: 'center',
                         width: "100%",
                         fontSize: 7,
                         marginTop: 5,
                         borderWidth: 0.5
                       }} >
             
             
                         <View style={{
             
                           width: '33.35%',
                           textAlign: 'left',
                           paddingTop: 2,
                           paddingRight: 8,
                           paddingLeft: 8,
                           height: '100%',
                           textAlign: 'left',
                        
                         }}>
                         
                         <Text style={{
                             fontFamily: "Helvetica-Bold",
                             fontSize: 14
                         }}>{"Stiffness (kPa)"}</Text>
                        
             
                       <View style={{
                         flexDirection:'row',
                         justifyContent:"space-between"
                       
                       }}>
                          <Text style={{
                             fontFamily: "Helvetica",
                             paddingTop:4,
                             fontSize: 10
                         }}>{"Median"}</Text>
                         <Text style={{
                             fontFamily: "Helvetica-Bold",
                             fontSize: 14
                         }}>{(parseInt(invoice["Stiffness(kPa)"])).toFixed(2)}</Text>
                       </View>
                       <View style={{
                         flexDirection:'row',
                         justifyContent:"space-between"
                       
                       }}>
                          <Text style={{
                             fontFamily: "Helvetica",
                           
                             fontSize: 10
                         }}>{"IQR/Median"}</Text>
                         <Text style={{
                             fontFamily: "Helvetica",
                             fontSize: 10
                         }}>{(parseInt(invoice["Stiffness IQR(kPa)"]))+"%"}</Text>
                       </View>
                         
                         
                         </View>
                         <View style={{
             
                           width: '33.35%',
                           textAlign: 'left',
                           paddingTop: 2,
                           borderLeft:0.5,
                           paddingLeft: 8,
                           paddingRight: 8,
                           flexDirection:"column",
                           height: '100%',
                           textAlign: 'left',
                  
                          
             
                         }}>
                             <Text style={{
                             fontFamily: "Helvetica-Bold",
                             fontSize: 14
                         }}>{"Measurements"}</Text>
             
                       <View style={{
                         flexDirection:'row',
                         justifyContent:"space-between"
                       
                       }}>
                          <Text style={{
                             fontFamily: "Helvetica",
                             fontSize: 10,
                             paddingTop:4,
                         }}>{"Success Rate"}</Text>
                         <Text style={{
                           
                             fontSize: 10,
                              paddingTop:4,
                         }}>{((parseInt(invoice["Valid Measurements"])/parseInt(invoice["Total Measurements"]))*100).toFixed(2)+"%"}</Text>
                       </View>
                       <View style={{
                         flexDirection:'row',
                         justifyContent:"space-between"
                       
                       }}>
                          <Text style={{
                             fontFamily: "Helvetica",
                           
                             fontSize: 10
                         }}>{"Valid/Total"}</Text>
                         <Text style={{
                             fontFamily: "Helvetica",
                             fontSize: 10
                         }}>{invoice["Valid Measurements"]+"/"+invoice["Total Measurements"]}</Text>
                       </View>
                         
                         
                           </View>
                         <View style={{
             
                           width: '33.35%',
                           textAlign: 'left',
                           paddingTop: 2,
                           borderLeft:0.5,
                           paddingRight: 8,
                           paddingLeft: 8,
                           height: '100%',
                           textAlign: 'left'
                         }}>
                            <Text style={{
                             fontFamily: "Helvetica-Bold",
                             fontSize: 14
                         }}>{"UAP (dB/m)"}</Text>
                            
                       <View style={{
                         flexDirection:'row',
                         justifyContent:"space-between"
                       
                       }}>
                          <Text style={{
                             fontFamily: "Helvetica",
                             paddingTop:4,
                             fontSize: 10
                         }}>{"Median"}</Text>
                         <Text style={{
                             fontFamily: "Helvetica-Bold",
                             fontSize: 14
                         }}>{parseInt(invoice["UAP(dB/m)"])}</Text>
                       </View>
                       <View style={{
                         flexDirection:'row',
                         justifyContent:"space-between"
                       
                       }}>
                          <Text style={{
                             fontFamily: "Helvetica",
                           
                             fontSize: 10
                         }}>{"IQR/Median"}</Text>
                         <Text style={{
                             fontFamily: "Helvetica",
                             fontSize: 10
                         }}>{parseInt(invoice["UAP IQR(dB/m)"])+"%"}</Text>
                       </View>
                         
                           
                           </View>
                       
             
                       </View>
                       <View
                       style={
                         {
                           display:"flex",
                           flexDirection:"column",
                           justifyContent:"center",
                           border:0.5,
                           paddingTop:10,
                           marginTop:5
                         }
                       }
                       >
                       <View
                       style={
                         {
                           display:"flex",
                           flexDirection:"row",
                           justifyContent:"center"
                          
                         }
                       }
                       >
                         <View style={{
                           borderRight:3,
                           borderStyle:"dashed",
                           paddingRight:10,
                           marginTop:-1
                         }}>
                       <Image style={{
                          width: 240,
                          height: 180
                       }} src={liver} />
                       </View>
                       <Image style={{
                          width: 250,
                          height: 180
                       }} src={ultrasound} />
                       </View>
                       <Text style={{
                         fontSize:10,
                         textAlign:"center",
                         alignSelf:"center",
                         marginTop:10
                       }}>{"For Reference Only, Please consult your physician for further diagnosis"}</Text>
                       </View>
                      
                       </View>
                   
                       </View>
             
                       <View style={{
                         paddingLeft:10,
                         paddingRight:10
                       }}>
                       <Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>Kindly Remind:</Text>
                        <Text style={{ 
                         fontFamily: "Helvetica",
                         fontSize: 7
                        }}>LSM: <Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>1.0kPa</Text> ~ <Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>7.3kPa</Text> is normal</Text>
                         <Text style={{ 
                         fontFamily: "Helvetica",
                         fontSize: 7
                        }}>LSM: <Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>7.3kPa</Text> ~ <Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>12.4kPa</Text>, suggest for follow up test within 3-6 month and suggest to change the unhealthy living habbit e.g smoking, alcohal drinking, stay up late, eating irregularly or unhealthy, feel depression or irritable, less exercises etc.</Text>
                       <Text style={{ 
                         fontFamily: "Helvetica",
                         fontSize: 7
                        }}>LSM: more than <Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>12.4kPa</Text>, suggest for more diagnosis, in clinical, these reasons may cause the LSM high, such as: Hepatitis, Fatty liver, ALD, DILI, Autoimmune liver disease, or Biliary tract disease etc.</Text>
                      <Text style={{ 
                         fontFamily: "Helvetica",
                         fontSize: 7
                        }}>{"UAP : "}<Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>{"<244dB/m"}</Text>{" is normal"}</Text>
                      
                      <Text style={{ 
                         fontFamily: "Helvetica",
                         fontSize: 7
                        }}>{"UAP : "}<Text style={{ 
                         fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>{">244dB/m"}</Text>{" the more the UAP value, the higher Fat content in liver, suggest to control body weight, and adjust the diet, and do some exercies"}</Text>
                      <Text style={{ 
                        fontFamily: "Helvetica-Bold",
                         fontSize: 7
                        }}>Note</Text>
                       <Text style={{ 
                         fontFamily: "Helvetica",
                         fontSize: 7
                        }}>The following reasons will affect the LSM: Hepatic ascites, narrow intercostal space, hepatic inflammation ( abnormal bilirubin, abnormal transaminasem etc.), cholestasis, hepatic congestion, alcohol consumption etc.</Text>
                      
                       </View>
               </View>
             </View>
                        <View fixed style={{ position: 'absolute', width: '100%', bottom: 0, }}>


                            <View style={{ marginTop: 0 }}>

                                <Text fixed style={{ width: "100%", left: 0, paddingLeft: 50, position: "absolute", fontSize: 6 }} render={({ pageNumber, totalPages }) => (
                                    "Page " + `${pageNumber} / ${totalPages}`
                                )} />
                                <View style={{ alignItems: 'flex-end', marginRight: -40 }}>
                                    {/* <Text style={{ fontSize: 6 }}>User ID : {capitalizeFirstLetter(invoice.DoctorSignature)} Printed on Date   {invoice.TodayDate} at {invoice.TodayTime}   Inv.Date: {invoice.InvoiceDate[0]}  Inv.Time {invoice.InvoiceTime[0]}</Text> */}
                                    <Text style={{ color: 'red', bottom: 0, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>

                                    
                                </View>
                            </View>


                            <View>
                                <Image src={footer} style={{ width: "110%", marginTop: 10, height: 80 }} />
                            </View>
                        </View>

                    </Page>
                    </Document>
                   
    )
}

export default Report